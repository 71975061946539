import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import ReduceErrors from "../../lib/reduceErrors";
import { TOGGLE_SIDEBAR } from "./types/common";

export const searchPharmacies = () => ({
  type: TOGGLE_SIDEBAR
});

export const addContactForm = data => async dispatch => {
  try {
    const response = await axios.post(`${API_URL}/api/web/contact_form`, data);

    if (response.status === 200 || response.status === 204) {
      toast.success(`Mensaje enviado exitosamente.`);
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      let responseError;

      if (error.response.data.errors) {
        responseError = ReduceErrors(error.response.data.errors);

        responseError.forEach(item => {
          toast.error(item);
        });
      } else {
        toast.error(error.response.data.message);
      }
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0004)");
    }
    return error.response.status;
  }
};

export const sendMedicalResidenceMail = data => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/medical_residence_mail`,
      data
    );

    if (response.status === 200 || response.status === 204) {
      toast.success(`Mensaje enviado exitosamente.`);
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0005)");
    }
    return error.response.status;
  }
};

export const resetAllMaps = () => ({
  type: "RESET_MAP_REDUCER"
});
