import React from "react";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173013637-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Footer = () => {
  return (
    <footer>
      <FooterMobile />
      <FooterDesktop />
    </footer>
  );
};

export default Footer;
