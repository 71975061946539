import React from "react";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/tramites.svg";
import UptPersonalDataForm from "../UptPersonalDataForm";

const UpdatePersonalData = () => {
  return (
    <>
      <PanelTitle icon={Icon}>
        Trámites / Actualización de datos personales{" "}
      </PanelTitle>
      <Row className="mx-0 mt-5">
        <Col xs={12} className="pb-4">
          <UptPersonalDataForm />
        </Col>
      </Row>
    </>
  );
};

export default UpdatePersonalData;
