import React from "react";
import { Link } from "react-router-dom";
import { Col, CardHeader, CardBody } from "reactstrap";
import { Accordion, Button } from "react-bootstrap";

import PageWrapper from "../PageWrapper";

import Mail from "../../assets/img/mail.svg";
import Phone from "../../assets/img/phone-icon.svg";
import Map from "../../assets/img/maplocation.svg";
import CaretDown from "../../assets/img/caret-down-menu.svg";
import Check from "../../assets/img/check-circle-regular.svg";
import ResidenciasForm from "../ResidenciasForm";

const Content = () => (
  <PageWrapper>
    <Col xs={12}>
      <Accordion>
        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="faqs__titles w-100"
            >
              Datos de la Clínica
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="0">
            <CardBody className="body-wrapper">
              <span className="d-block mb-2">
                <span className="semi-bold-font">Clínica Alta Salud</span>
              </span>

              <ul className="list-unstyled">
                <li className="mb-2 d-flex align-items-center">
                  <img src={Map} alt="map icon" className="icon-align pr-2" />{" "}
                  <span>Avenida Nazca 1169, CABA.</span>
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <img
                    src={Phone}
                    alt="phone icon"
                    className="icon-align pr-2"
                  />{" "}
                  <span>
                    <a href="tel:4588-5800">4588-5800.</a>
                  </span>
                </li>
              </ul>

              <span className="d-block pt-2">
                <span>Clínica Médica:</span>{" "}
                <img
                  src={Mail}
                  alt="mail icon"
                  className="pr-1 centros-medicos__icons-info"
                />
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciaclinicamedica@altasalud.com.ar"
                >
                  residenciaclinicamedica@altasalud.com.ar
                </a>
              </span>
              <span className="d-block pt-2">
                <span>Cardiología:</span>{" "}
                <img
                  src={Mail}
                  alt="mail icon"
                  className="pr-1 centros-medicos__icons-info"
                />
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciacardiologia@altasalud.com.ar"
                >
                  residenciacardiologia@altasalud.com.ar
                </a>
              </span>
              <span className="d-block mt-4">
                Duración: 4 años de residencia y opción a jefatura de
                residentes.
              </span>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              className="faqs__titles w-100"
            >
              Proceso de inscripción
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="1">
            <CardBody className="body-wrapper">
              <span className="d-block">
                1. Completar el Formulario de Inscripción y adjuntar la
                documentación requerida:
                <ul className="pt-3 ua_ul">
                  <li className="ua_list_estetica">
                    <img
                      src={Check}
                      alt=""
                      className="planes__icon-check icon-check-res-med"
                    />{" "}
                    Matricula nacional o en tramite
                  </li>
                  <li className="ua_list_estetica">
                    <img
                      src={Check}
                      alt=""
                      className="planes__icon-check icon-check-res-med"
                    />
                    Titulo (en caso de ser extranjero la convalidación)
                  </li>
                  <li className="ua_list_estetica">
                    <img
                      src={Check}
                      alt=""
                      className="planes__icon-check icon-check-res-med"
                    />
                    CV
                  </li>
                  <li className="ua_list_estetica">
                    <img
                      src={Check}
                      alt=""
                      className="planes__icon-check icon-check-res-med"
                    />
                    Inscripción al monotributo
                  </li>
                  <li className="ua_list_estetica">
                    <img
                      src={Check}
                      alt=""
                      className="planes__icon-check icon-check-res-med"
                    />
                    DNI
                  </li>
                </ul>
              </span>
              <span className="d-block">
                2. Enviar documentación respaldatoria que no se haya incluido en
                el Formulario de Inscripción a{" "}
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciaclinicamedica@altasalud.com.ar"
                >
                  residenciaclinicamedica@altasalud.com.ar
                </a>{" "}
                o{" "}
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciacardiologia@altasalud.com.ar"
                >
                  residenciacardiologia@altasalud.com.ar
                </a>{" "}
                lo antes posible y hasta antes de la fecha límite de
                inscripción.
              </span>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="2"
              className="faqs__titles w-100"
            >
              Formulario de Inscripción
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="2">
            <CardBody className="body-wrapper">
              <ResidenciasForm />
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="3"
              className="faqs__titles w-100"
            >
              ¿Cómo se compone la nota final?
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="3">
            <CardBody className="body-wrapper">
              <div>1. Examen de Residencias</div>
              <div>2. Orden de merito parcial</div>
              <div>3. Entrevista personal</div>
              <div>4. Orden de merito final</div>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="4"
              className="faqs__titles w-100"
            >
              Preguntas Frecuentes
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="4">
            <CardBody className="body-wrapper">
              <p>
                <span className="d-block titles-info-interes">
                  1. ¿Cómo debo enviar la documentación solicitada?
                </span>
                La documentación debe ser escaneada (en lo posible formato PDF)
                y enviada por correo electrónico a{" "}
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciaclinicamedica@altasalud.com.ar"
                >
                  residenciaclinicamedica@altasalud.com.ar
                </a>{" "}
                o{" "}
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciacardiologia@altasalud.com.ar"
                >
                  residenciacardiologia@altasalud.com.ar
                </a>
                .
              </p>
              <p>
                <span className="d-block titles-info-interes">
                  2. ¿Cómo se aprueba el examen?
                </span>
                El examen se aprueba respondiendo en forma correcta el 60% de
                las preguntas. Es condición excluyente aprobarlo para continuar
                el proceso de selección e ingresar a la siguiente instancia del
                concurso.
              </p>
              <p>
                <span className="d-block titles-info-interes">
                  3. ¿El examen es único para todas las especialidades?
                </span>
                Sí, se toma un único examen para todas las especialidades
                médicas.
              </p>
              <p>
                <span className="d-block titles-info-interes">
                  4. ¿Cómo se compone la nota final?
                </span>
                <span className="d-block">I. Examen de Residencias</span>
                <span className="d-block">II. Orden de merito parcial</span>
                <span className="d-block">III. Entrevista personal</span>
                <span className="d-block">IV. Orden de merito final</span>
              </p>
              <p>
                <span className="d-block titles-info-interes">
                  5. ¿Puede uno inscribirse a más de una especialidad?
                </span>
                No, sólo a una.
              </p>
              <p>
                <span className="d-block titles-info-interes">
                  6. ¿Qué es una ayudantía?
                </span>
                Una ayudantía es la labor docente de un alumno de la carrera de
                Medicina y tiene un certificado otorgado por su universidad.
              </p>
              <p>
                <span className="d-block titles-info-interes">
                  8. ¿En dónde debe tramitarse la convalidación de su título?
                </span>
                Ingresar al sitio de Convalidaciones de títulos universitarios
                extranjeros haciendo click{" "}
                <a
                  className="links-inner-faqs"
                  href="https://convalidaciones.siu.edu.ar/"
                >
                  aquí
                </a>
                .
              </p>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="5"
              className="faqs__titles w-100"
            >
              Reglamento de la residencia
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="5">
            <CardBody className="body-wrapper">
              <Link to="reglamento-residencia" className="links-inner-faqs">
                Click aquí
              </Link>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="6"
              className="faqs__titles w-100"
            >
              Contacto
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="6">
            <CardBody className="body-wrapper">
              <div className="mb-2">
                <div className="semi-bold-font mb-2">
                  Residencia Clínica Médica
                </div>
                <div>Jefa de servicio Dra. Marisa Andrea Lopez.</div>
                <div>Instructor Dr. Raúl Romero.</div>

                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="Mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      <a
                        className="links-inner-faqs"
                        href="mailto: residenciaclinicamedica@altasalud.com.ar"
                      >
                        residenciaclinicamedica@altasalud.com.ar
                      </a>
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Phone}
                      alt="Phone icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      <a href="tel:45885800">4588-5800</a>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="pt-4 mb-2">
                <div className="semi-bold-font mb-2">
                  Residencia de Cardiología.
                </div>
                <div>Jefe de servicio Dr. Pablo Czerniuk.</div>

                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="Mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      <a
                        className="links-inner-faqs"
                        href="mailto: residenciacardiologia@altasalud.com.ar"
                      >
                        residenciacardiologia@altasalud.com.ar
                      </a>
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Phone}
                      alt="Phone icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      <a href="tel:45885800">4588-5800</a>
                    </span>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </Col>
  </PageWrapper>
);

export default Content;
