import React from "react";
import { Col } from "reactstrap";

import PageWrapper from "../PageWrapper";
import PageTitle from "../PageTitle";
import Carousel from "../Carousel";

import Image1 from "../../assets/img/carousel_quienes_somos_1.jpg";
import Image2 from "../../assets/img/carousel_quienes_somos_2.jpg";
import Image3 from "../../assets/img/carousel_quienes_somos_3.jpg";
import Image4 from "../../assets/img/carousel_quienes_somos_4.jpg";
import Image5 from "../../assets/img/carousel_quienes_somos_5.jpg";

import CheckIcon from "../../assets/img/check-circle-regular.svg";

const CarouselItems = [Image1, Image2, Image3, Image4, Image5];

const Content = () => {
  return (
    <PageWrapper>
      <Col xs="12">
        <PageTitle>En cada etapa de tu vida</PageTitle>
      </Col>
      <Col xs="12" sm="6">
        <p>
          Asumimos el compromiso de brindar el más completo servicio en salud,
          además de nuestra responsabilidad médica.
        </p>
        <p>
          Alta Salud&nbsp;reafirma su vocación de servicio en cada etapa de su
          vida, con calidad y responsabilidad, es nuestro deseo que usted reciba
          la calidez en el trato y el profesionalismo que espera y se merece.
        </p>
        <p>
          Tenemos como prioridad ser una empresa reconocida socialmente, fundada
          en normas prácticas, con la capacidad de innovación y aprendizaje,
          orientadas a satisfacer las exigencias de la población.
        </p>
        <ul className="quienes-somos__destacados">
          <li className="quienes-somos__destacados__items">
            <img src={CheckIcon} alt="" className="planes__icon-check" />{" "}
            Infraestructura sanatorial propia
          </li>
          <li className="quienes-somos__destacados__items">
            <img src={CheckIcon} alt="" className="planes__icon-check" />{" "}
            Nuestros centros cuentan con tecnología de primer nivel
          </li>
          <li className="quienes-somos__destacados__items">
            <img src={CheckIcon} alt="" className="planes__icon-check" /> Staff
            médico de excelente calidad y profesionalismo
          </li>
          <li className="quienes-somos__destacados__items">
            <img src={CheckIcon} alt="" className="planes__icon-check" />{" "}
            Preparación académica gracias a nuestras residencias médicas
          </li>
        </ul>
      </Col>
      <Col xs="12" sm="6">
        <Carousel items={CarouselItems} />
      </Col>
    </PageWrapper>
  );
};

export default Content;
