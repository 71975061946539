import React from "react";

const Error = () => {
  return (
    <div className="page-not-found">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-sm-3">
            <h1 className="m-0 text-center">
              Oops! La página que estabas buscando no existe.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
