import {
  LOADING_FACTURAS,
  GET_FACTURAS_SUCCESS,
  GET_FACTURAS_FAILURE,
  SET_FACTURAS_PAGE,
  RESET_FACTURAS,
} from "../actions/types/facturas";

const initialState = {
  facturas: [],
  loading: true,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null,
  },
  meta: {
    currentPage: 1,
    from: null,
    to: null,
    total: null,
  },
  filters: {
    page: 1,
  },
  openTab: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FACTURAS: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_FACTURAS_SUCCESS: {
      return {
        ...state,
        loading: false,
        facturas: [...action.payload.facturas],
      };
    }

    case GET_FACTURAS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_FACTURAS_PAGE: {
      return {
        ...state,
        loading: false,
        meta: {
          ...state.meta,
          currentPage: action.payload.page,
        },
        filters: {
          ...state.filters,
          page: action.payload.page,
        },
      };
    }

    case RESET_FACTURAS: {
      return {
        ...state,
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};
