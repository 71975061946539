import React, { useState } from "react";
import Iframe from "react-iframe";
import ContentLoader from "react-content-loader";

const IframeC = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoading = () => {
    setIsLoading(false);
  };

  return (
    <div className="iframe-wrapper">
      {isLoading && (
        <div className="iframe-loader">
          <ContentLoader
            height={300}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="14" rx="5" ry="5" width="100%" height="160" />
          </ContentLoader>
        </div>
      )}
      <Iframe
        url={url}
        width="100%"
        className="laboratory-iframe"
        position="relative"
        onLoad={() => handleLoading()}
      />
    </div>
  );
};

export default IframeC;
