import React from "react";
import Moment from "react-moment";

const TurnosItem = ({ especialidad, fecha, medico }) => {
  return (
    <tr>
      <td>
        <span className="bold-table">{medico || "-"}</span>
        <span className="d-block">{especialidad || ""}</span>
      </td>
      <td>
        <Moment locale="es" format="LLL">
          {fecha}
        </Moment>
      </td>
    </tr>
  );
};

export default TurnosItem;
