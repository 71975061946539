import React from "react";
import Banner from "../components/Banner";
import Content from "../components/Residencias/Reglamento";

import ImageBanner from "../assets/img/reglamento_residencia_medica.jpg";

const ReglamentoResidencia = () => (
  <>
    <Banner title="REGLAMENTO RESIDENCIA MÉDICA" image={ImageBanner} size="6" />
    <Content />
  </>
);

export default ReglamentoResidencia;
