import React from "react";
import Moment from "react-moment";
import moment from "moment";
import { Row, Col } from "reactstrap";

import { connect } from "react-redux";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/dd_personales.svg";
import PersonalDataLoading from "../PersonalDataLoading";

const PersonalData = ({ user }) => {
  return (
    <>
      <PanelTitle icon={Icon}>Datos Personales</PanelTitle>
      {!user ? (
        <Row className="mx-0 py-3 py-sm-5">
          <Col xs={12} sm={6}>
            <PersonalDataLoading />
          </Col>
          <Col xs={12} sm={6}>
            <PersonalDataLoading />
          </Col>
        </Row>
      ) : (
        <Row className="mx-0 py-3 py-sm-5">
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Nombre y Apellido:</span>
              <span>{user.full_name || "-"}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">
                Tipo y nro de documento:
              </span>
              <span>
                {user.personal_id_type} {user.personal_id}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Fecha de nacimiento:</span>
              <span>
                <Moment locale="es" format="LL">
                  {user.born_date}
                </Moment>
              </span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Edad:</span>
              <span>
                {moment().diff(moment(user.born_date, "YYYY-MM-DD"), "years")}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Dirección:</span>
              <span>{user.address || "-"}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Mail:</span>
              <span>{user.email || "-"}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Teléfonos fijo:</span>
              <span>{user.phone || "-"}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Nro Afiliado:</span>
              <span>{user.membership_number || "-"}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Teléfonos celular:</span>
              <span>{user.cellphone || "-"}</span>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="dd-pp__lines w-100">
              <span className="dd-pp__item-title">Plan Actual:</span>
              <span>{user.plan || "-"}</span>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, null)(PersonalData);
