import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";

// Pages
import Home from "./pages/Home";
import Plans from "./pages/Planes";
import CartillaMedica from "./pages/CartillaMedica";
import Farmacias from "./pages/Farmacias";
import CentrosMedicos from "./pages/CentrosMedicos";
import QuienesSomos from "./pages/QuienesSomos";
import Reglamento from "./pages/Reglamento";
import Preguntas from "./pages/Preguntas";
import EsteticaBienestar from "./pages/EsteticaBienestar";
import PlanVacunar from "./pages/PlanVacunar";
import EscuelaEnfermeria from "./pages/EscuelaEnfermeria";
import ResidenciaMedica from "./pages/ResidenciaMedica";
import ReglamentoResidencia from "./pages/ReglamentoResidencia";
import Login from "./pages/Login";
import Panel from "./pages/Panel";
import ScrollToTop from "./components/ScrollToTop";
import Contacto from "./pages/Contacto";
import RRHH from "./pages/RRHH";
import UniversalAssitance from "./pages/UniversalAssitance";
import Error from "./pages/Error";

const App = () => {
  return (
    <>
      <ToastContainer position="bottom-right" />
      <BrowserRouter>
        <Layout>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/nuestros-planes" component={Plans} />
              <Route path="/cartilla-medica" component={CartillaMedica} />
              <Route path="/farmacias" component={Farmacias} />
              <Route path="/centros-medicos" component={CentrosMedicos} />
              <Route path="/quienes-somos" component={QuienesSomos} />
              <Route path="/reglamento" component={Reglamento} />
              <Route path="/preguntas-frecuentes" component={Preguntas} />
              <Route
                path="/servicios-estetica-bienestar"
                component={EsteticaBienestar}
              />
              <Route path="/plan-vacunar" component={PlanVacunar} />
              <Route
                path="/universal-assistance"
                component={UniversalAssitance}
              />
              <Route path="/escuela-enfermeria" component={EscuelaEnfermeria} />
              <Route path="/residencia-medica" component={ResidenciaMedica} />
              <Route
                path="/reglamento-residencia"
                component={ReglamentoResidencia}
              />
              <Route path="/contacto" component={Contacto} />
              <Route path="/trabaja-con-nosotros" component={RRHH} />
              <Route path="/login" component={Login} />
              <Route path="/panel" component={Panel} />
              <Route component={Error} />
            </Switch>
          </ScrollToTop>
        </Layout>
      </BrowserRouter>
    </>
  );
};

export default App;
