import {
  LOADING_IMAGENES,
  GET_IMAGENES_SUCCESS,
  GET_IMAGENES_FAILURE,
  SET_IMAGENES_PAGE,
  RESET_IMAGENES
} from "../actions/types/imagenes";

const initialState = {
  imagenes: [],
  loading: true,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    currentPage: 1,
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 0
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_IMAGENES: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_IMAGENES_SUCCESS: {
      return {
        ...state,
        loading: false,
        imagenes: action.payload.imagenes
      };
    }

    case GET_IMAGENES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_IMAGENES_PAGE: {
      return {
        ...state,
        loading: false,
        meta: {
          ...state.meta,
          currentPage: action.payload.page
        },
        filters: {
          ...state.filters,
          page: action.payload.page
        }
      };
    }

    case RESET_IMAGENES: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
