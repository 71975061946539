import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Plans/Content";
import PlansImage from "../assets/img/planes.jpg";

const NuestrosPlanes = () => (
  <>
  <Tags 
    title="Elegi tu plan" 
    description="Planes de salud para toda la familia. A-10 plus. C-60 plus." 
    keywords="Salud, A-10 plus, c-60 plus, Plan para jubilados, Plan familiar, Economico, Sin limite de edad, Preexistencia, Emergencias domiciliarias," 
    canonical="/nuestros-planes" />
    <Banner
      title="NUESTROS PLANES"
      image={PlansImage}
      subtitle="Conocé nuestros planes y elegí el más adecuado para vos."
      height="planes"
    />
    <Content />
  </>
);

export default NuestrosPlanes;
