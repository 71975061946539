import React from "react";
import { Col } from "reactstrap";

import PageWrapper from "../PageWrapper";
import ContactForm from "./ContactForm";

const Content = () => (
  <PageWrapper left>
    <Col xs={12} sm={6}>
      <h5 className="contact__title">¿Querés trabajar con nosotros?</h5>
      <p className="contatc__p mb-4">
        Completa el formulario y postúlate para una de nuestras vacantes.
      </p>
      <ContactForm />
    </Col>
  </PageWrapper>
);

export default Content;
