import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/MedicalEntity/Content";

import Image from "../assets/img/cartilla_medica.jpg";

const CartillaMedica = () => {
  const jsx = (
    <>
      Conocé nuestros planes y elegí el <br /> más adecuado para vos.
    </>
  );

  const title = (
    <>
      CARTILLA MÉDICA <br /> ONLINE
    </>
  );
  

  return (
    <>
     <Tags 
    title="Cartilla médica" 
    description="Cartilla médica completa con prestadores de alta calidad." 
    keywords="Cartilla médica, Prestadores medicos, Doctores, Medicos, Centro médico, Asistencia primaria, Guardia, Emergencias, Urgencias, Odontologia, Optica" 
    canonical="/cartilla-medica" />
      <Banner title={title} image={Image} subtitle={jsx} size="8" />
      <Content />
    </>
  );
};


export default CartillaMedica;
