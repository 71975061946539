import {
  LOADING_LABORATORIOS,
  GET_LABORATORIOS_SUCCESS,
  GET_LABORATORIOS_FAILURE,
  SET_LABORATORIOS_PAGE,
  RESET_LABORATORIOS
} from "../actions/types/laboratorios";

const initialState = {
  laboratory: [],
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_LABORATORIOS: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_LABORATORIOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        laboratory: action.payload.laboratory
      };
    }

    case GET_LABORATORIOS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_LABORATORIOS_PAGE: {
      return {
        ...state,
        loading: false,
        meta: {
          ...state.meta,
          currentPage: action.payload.page
        },
        filters: {
          ...state.filters,
          page: action.payload.page
        }
      };
    }

    case RESET_LABORATORIOS: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
