import React from "react";
import { Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SlideShow = ({ items }) => {
  return (
    <Carousel>
      {items.map((item, index) => (
        <Carousel.Item key={index}>
          <LazyLoadImage
            className="d-block w-100"
            src={item}
            effect="blur"
            alt={`slide ${item}`}
            loading="lazy"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default SlideShow;
