// COMMENT: CREATE THIS FILE
import {
  GET_MEDICAL_NAME_ENTITIES_FAILURE,
  GET_MEDICAL_NAME_ENTITIES_SUCCESS,
  LOADING_MEDICAL_NAME_ENTITIES,
} from "../actions/types/medicalNameEntities";

const initialState = {
  data: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MEDICAL_NAME_ENTITIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_MEDICAL_NAME_ENTITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
      };
    }

    case GET_MEDICAL_NAME_ENTITIES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
