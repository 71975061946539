import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Enfermeria/Content";

import ImageBanner from "../assets/img/escuela_enfermeria.jpg";

const EscuelaEnfermeria = () => (
  <>
    <Tags 
      title="Escuela de enfermería" 
      description="Preparando el futuro." 
      keywords="enfermeros, formacion academica, cuidado del paciente, asistencia sanitaria, enfermeros profesionales, auxiliares de enfermeria, clases" 
      canonical="/escuela-enfermeria" />
    <Banner title="ESCUELA DE ENFERMERÍA" image={ImageBanner} height="ee" />
    <Content />
  </>
);

export default EscuelaEnfermeria;
