const normalizeText = string =>
  string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export const getFormTypeByWord = (slug, formTypes) => {
  const parsedFormTypes = formTypes.map(item => ({
    label: normalizeText(item.label),
    id: item.value
  }));

  let item = parsedFormTypes.find(item => item.label.includes(slug));

  if (item) {
    return item.id;
  }

  return null;
};
