import {
  GET_DOCTYPES_LOADING,
  GET_TYPEDOCS_SUCCESS,
  GET_TYPEDOCS_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS
} from "../actions/types/auth";

const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  types: {
    loading: false,
    data: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCTYPES_LOADING: {
      return {
        ...state,
        types: {
          ...state.types,
          loading: true
        }
      };
    }

    case GET_TYPEDOCS_SUCCESS: {
      return {
        ...state,
        types: {
          ...state.types,
          loading: false,
          data: [...action.payload.types]
        }
      };
    }

    case GET_TYPEDOCS_FAILURE: {
      return {
        ...state,
        types: {
          ...state.types,
          loading: false
        }
      };
    }

    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        user: {
          ...state.user,
          ...action.payload.user
        },
        isAuthenticated: true
      };
    }

    case SIGN_OUT_SUCCESS: {
      return {
        ...state,
        ...initialState
      };
    }

    default:
      return state;
  }
};
