// COMMENT: CREATE THIS FILE
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_PHARMACIES_NAME,
  GET_PHARMACIES_NAME_FAILURE,
  GET_PHARMACIES_NAME_SUCCESS,
} from "./types/pharmaciesName";
import { LogOut } from "./auth";

export const getPharmaciesName = () => async (dispatch) => {
  dispatch({ type: LOADING_PHARMACIES_NAME });
  try {
    const baseUrl = `${API_URL}/api/web/pharmacy`;
    const pagination = `?page=1&pagination=1000`;

    let URL = `${baseUrl}${pagination}`;
    const response = await axios.get(URL);

    dispatch({
      type: GET_PHARMACIES_NAME_SUCCESS,
      payload: {
        data: [ 
          {
            value: "all",
            label: "TODOS",
          },
          ...response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ],
      },
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_PHARMACIES_NAME_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado.  (Error 0012)");
    }
    return error.response.status;
  }
};
