import React from "react";
import MedicalEntityItem from "./MedicalEntityItem";

import { connect } from "react-redux";

const Container = ({ medicalEntity }) => {
  return (
    <>
      {medicalEntity.length === 0 ? (
        <>
          <h2>No hay clinicas disponibles, busca una.</h2>
        </>
      ) : (
        <>
          <div className="result-wrapper mb-3">
            {medicalEntity.map(item => (
              <MedicalEntityItem key={item.id} medicalEntity={item} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  medicalEntity: state.medicalEntity.medicalEntity
});

export default connect(
  mapStateToProps,
  null
)(Container);
