import React from "react";
import Button from "../Button";
// import { gtag_report_conversion } from "analytics";

const SlideInfo = () => {
  return (
    <div className="home-slider__info-container">
      <hr className="home-slider_info__line" />
      <span className="home-slider_info">Ventas al 0800.333.2582</span>
      <h2 className="home-slider_title">
        ¿aún no sos
        <br />
        socio?
      </h2>
      {/* <Button url="/contacto" type="orange" onClick={() => {
        gtag_report_conversion('/contacto'); // Envía el evento de conversión
      }}>
        Asociarme
      </Button> */}
      <Button url="/contacto" type="orange" 
      onClick={() => {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-622659677/IfKXCOjcmoUZEN2Q9KgC',
          'value': 1.0,
          'currency': 'ARS'
        });
        
      }}>
        Asociarme
      </Button>
      
    </div>
    
  );
};




export default SlideInfo;