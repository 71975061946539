import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/About/Content";

import ImageBanner from "../assets/img/quienes_somos.jpg";

const QuienesSomos = () => (
  <>
   <Tags 
    title="Quienes somos" 
    description="Somos un equipo de especialistas en el área de salud." 
    keywords="Institución de salud,Centros medicos, Prepaga de salud, Cobertura médica, Servico de atencion al cliente, Consultas médicas, Infraestructura sanatorial propia, Tecnologia de primer nivel, Residencia medica" 
    canonical="/quienes-somos" />
    <Banner title="QUIÉNES SOMOS" image={ImageBanner} height="qs" />
    <Content />
  </>
);

export default QuienesSomos;
