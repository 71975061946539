import {
  LOADING_FORMTYPES,
  GET_FORMTYPES_SUCCESS,
  GET_FORMTYPES_FAILURE,
  RESET_FORMTYPES
} from "../actions/types/form_types";

const initialState = {
  formTypes: [],
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FORMTYPES: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_FORMTYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        formTypes: [...action.payload.formTypes]
      };
    }

    case GET_FORMTYPES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case RESET_FORMTYPES: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
