import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  PHARMACIES_LOADING,
  GET_PHARMACIES_SUCCESS,
  GET_PHARMACIES_FAILURE,
  SET_PHARMACY_PAGE,
  SET_PROVINCE_PHARMACY_ID,
  SET_AREA_PHARMACY_ID,
  SET_LOCATIONS_PHARMACY_ID,
  RESET_PHARMACIES,
  SET_PHARMACIES_NAME,
} from "./types/pharmacies";
import { LogOut } from "./auth";



var province_id_get = (new URLSearchParams(window.location.search)).get("province_id");
var location_id_get = (new URLSearchParams(window.location.search)).get("location_id");
var area_id_get = (new URLSearchParams(window.location.search)).get("area_id");
var pharmacies_name = (new URLSearchParams(window.location.search)).get("pharmacies_name");



export const searchPharmacies = (filters, firstPage) => async dispatch => {
  dispatch({ type: PHARMACIES_LOADING });

  try {
    const baseUrl = `${API_URL}/api/web/pharmacy`;
    const pagination = `?page=${firstPage ? "1" : filters.page}&pagination=5`;

    let URL = `${baseUrl}${pagination}`;


    if (province_id_get && !filters.province_id && !filters.pharmacies_name) {
      URL = `${URL}${`&province_id=${province_id_get}`}`;
    }

    if (location_id_get  && !filters.province_id && !filters.pharmacies_name) {
      URL = `${URL}${`&location_id=${location_id_get}`}`;
    }

    if (area_id_get  && !filters.province_id && !filters.pharmacies_name) {
      URL = `${URL}${`&area_id=${area_id_get}`}`;
    }
    
    if (pharmacies_name && !filters.pharmacies_name) {
      URL = `${URL}${`&name=${pharmacies_name}`}`;
    }

    if (filters.pharmacies_name && filters.pharmacies_name !== 'TODOS') {
      URL = `${URL}${`&name=${filters.pharmacies_name}`}`;
    }

    if (filters.province_id) {
      URL = `${URL}${`&province_id=${filters.province_id}`}`;
    }

    if (filters.location_id) {
      URL = `${URL}${`&location_id=${filters.location_id}`}`;
    }

    if (filters.area_id) {
      URL = `${URL}${`&area_id=${filters.area_id}`}`;
    }

    // COMMENT: START CODE
    console.log('URL:', URL);
    console.log('filters:', filters);

    const response = await axios.get(URL);

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: {
        pharmacies: response.data.data,
        markers: response.data.data.map(item => ({
          lat: item.lat,
          lng: item.lon,
          name: item.name,
          id: item.id
        })),
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          currentPage: response.data.meta.current_page,
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_PHARMACIES_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0011)");
    }
    return error.response.status;
  }
};

export const setPage = page => ({
  type: SET_PHARMACY_PAGE,
  payload: {
    page
  } 
});

export const setProvinceId = id => ({
  type: SET_PROVINCE_PHARMACY_ID,
  payload: {
    value: id
  }
});

export const setAreaId = id => ({
  type: SET_AREA_PHARMACY_ID,
  payload: {
    value: id
  }
});

export const setLocationId = id => ({
  type: SET_LOCATIONS_PHARMACY_ID,
  payload: {
    value: id
  }
});

export const resetReducer = () => ({
  type: RESET_PHARMACIES
});


  // COMMENT: START CODE 
  export const setPharmaciesName = (name) => ({
    type: SET_PHARMACIES_NAME,
    payload: {
      value: name,
    },
  });
    // COMMENT: END CODE 