import React from "react";
import Map from "../../assets/img/maplocation.svg";
import Phone from "../../assets/img/phone-icon.svg";
import Info from "../../assets/img/info.png";

const MedicalEntityItem = ({ medicalEntity }) => (
  <div className="container__resultado-busqueda">
    {/* <h6 className="resultado-busqueda__title">{medicalEntity.name} <div className="icon_resultado mr-2"><img src={Corazon_no}  alt="Corazon" title="Dirección / Ubicación" className="w-100" /></div></h6>*/}
    <h6 className="resultado-busqueda__title">{medicalEntity.name} </h6>

    <span className="d-flex resultado-busqueda__text">
      <div className="icon_resultado mr-2">
        <img
          src={Map}
          alt="Dirección / Ubicación"
          title="Dirección / Ubicación"
          className="w-100"
        />
      </div>
      {medicalEntity.address}
    </span>
    {medicalEntity.address_extra && medicalEntity.address_extra !== "-" && (
      <span className="d-flex resultado-busqueda__text">
        <div className="invisible icon_resultado mr-2">
          <img
            src={Map}
            alt="Dirección / Ubicación"
            title="Dirección / Ubicación"
            className="w-100"
          />
        </div>
        <div className="mr-2">
          <p className="m-0">Piso y Dpto:</p>
        </div>
        {medicalEntity.address_extra}
      </span>
    )}
    <span className="d-flex resultado-busqueda__text">
      <div className="icon_resultado mr-2">
        <img src={Phone} alt="Teléfonos" title="Teléfonos" className="w-100" />
      </div>
      {medicalEntity.phone}
    </span>

    {/* <SetFavouriteIcon enFavoritos="0" /> */}

    {medicalEntity.note && (
      <span className="d-flex resultado-busqueda__text parrafo">
        <div className="icon_resultado mr-2">
          <img
            src={Info}
            alt="Información Adicional"
            title="Información Adicional"
            className="w-100"
          />
        </div>
        {medicalEntity.note}
      </span>
    )}
  </div>
);

export default MedicalEntityItem;
