import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import * as Yup from "yup";
//Imagenes
import AddFile from "../assets/img/agregar_archivo.svg";
import Trash from "../assets/img/trash.png";
//imagenes

import { getDocTypes } from "../redux/actions/auth";
import { sendMedicalResidenceMail } from "../redux/actions/common";
import FormLoading from "./FormLoading";
import { customStyles } from "./styles/common";
import SubmitBtn from "./SubmitBtn";
import DatePicker from "./DatePicker";

const specialties = [
  { value: 6, label: "Cardiología" },
  { value: 22, label: "Clínica Médica" },
];

const ResidenciasForm = ({
  sendMedicalResidenceMail,
  history,
  getDocTypes,
  docTypes,
  docTypesLoading,
}) => {
  const [fileName, setFileName] = useState(null);
  const [fileName02, setFileName02] = useState(null);
  const [fileName03, setFileName03] = useState(null);
  const [fileName04, setFileName04] = useState(null);
  const [isVisibleFrmGrp02, setDisplayFrmGrp02] = useState(false);
  const [isVisibleFrmGrp03, setDisplayFrmGrp03] = useState(false);
  const [isVisibleFrmGrp04, setDisplayFrmGrp04] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    getDocTypes();
  }, [getDocTypes]);

  if (docTypesLoading) return <FormLoading />;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: "Residencias Médicas",
        medical_specialty: "",
        fullName: "",
        born_date: "",
        country: "",
        address: "",
        phone: "",
        cellphone: "",
        email: "",
        university: "",
        average: "",
        document_type: "",
        document_id: "",
        file_name: "",
        file_ext: "",
        cv: "",
        attachment02: "",
        attachment03: "",
        attachment04: "",
        file_name_02: "",
        file_ext_02: "",
        file_name_03: "",
        file_ext_03: "",
        file_name_04: "",
        file_ext_04: "",
      }}
      validationSchema={Yup.object().shape({
        medical_specialty: Yup.string().required("Requerido."),
        email: Yup.string()
          .email("Debe ser un email valido.")
          .required("Requerido."),
        fullName: Yup.string().required("Requerido"),
        phone: Yup.string().required("Requerido"),
        document_type: Yup.string().required("Requerido"),
        document_id: Yup.string().required("Requerido"),
        cv: Yup.string().required("Requerido"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const {
          medical_specialty,
          fullName,
          phone,
          email,
          document_type,
          document_id,
          born_date,
          country,
          address,
          university,
          average,
          cellphone,
          file_name,
          file_ext,
          cv,
          attachment02,
          attachment03,
          attachment04,
          file_name_02,
          file_ext_02,
          file_name_03,
          file_ext_03,
          file_name_04,
          file_ext_04,
        } = values;

        const newData = {
          speciality: medical_specialty.label,
          name: fullName,
          telephone: phone,
          email: email,
          doc_type: document_type.value,
          dni: document_id,
          birthdate: moment(born_date).format("DD-MM-YYYY"),
          country: country,
          address: address,
          university: university,
          average: average,
          cellphone: cellphone,
          file_name: file_name,
          file_ext: file_ext,
          cv: cv,
          attachment02: attachment02,
          attachment03: attachment03,
          attachment04: attachment04,
          file_name_02: file_name_02,
          file_ext_02: file_ext_02,
          file_name_03: file_name_03,
          file_ext_03: file_ext_03,
          file_name_04: file_name_04,
          file_ext_04: file_ext_04,
        };

        const response = await sendMedicalResidenceMail(newData);

        setSubmitting(false);

        if (response === 204) {
          history.push("/");
        }
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        handleBlur,
        values,
        handleChange,
        setFieldValue,
      }) => {
        const formattedDate = moment(values.born_date).format("DD/MM/YYYY");

        return (
          <Form className="mt-4">
            <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
              <FormGroup>
                <Select
                  className={`${
                    errors.medical_specialty && touched.medical_specialty
                      ? "select-error"
                      : ""
                  }`}
                  classNamePrefix="custom-select"
                  placeholder="Seleccione una especialidad"
                  styles={customStyles}
                  options={specialties}
                  value={values.medical_specialty}
                  onChange={(value) => {
                    setFieldValue("medical_specialty", value);
                  }}
                />
                {errors.medical_specialty && touched.medical_specialty && (
                  <FormFeedback className="d-block">
                    {errors.medical_specialty}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Nombre y Apellido"
                  className={`form-control__round ${
                    errors.fullName && touched.fullName && "is-invalid"
                  }`}
                />
                {errors.fullName && touched.fullName && (
                  <FormFeedback className="d-block">
                    {errors.fullName}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup className="position-relative">
                <Input
                  className={`form-control__round ${
                    errors.fullName && touched.fullName && "is-invalid"
                  }`}
                  type="text"
                  placeholder="Fecha de nacimiento"
                  onClick={() => setShowCalendar(true)}
                  onChange={() => null}
                  value={formattedDate === "Invalid date" ? "" : formattedDate}
                />

                <DatePicker
                  active={showCalendar}
                  value={values.born_date}
                  onChange={(date) => setFieldValue("born_date", date)}
                  closeModal={() => setShowCalendar(false)}
                />

                {errors.born_date && touched.born_date && (
                  <FormFeedback className="d-block">
                    {errors.born_date}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Pais de Nacimiento"
                  className={`form-control__round ${
                    errors.country && touched.country && "is-invalid"
                  }`}
                />
                {errors.country && touched.country && (
                  <FormFeedback className="d-block">
                    {errors.country}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Domicilio: Calle y Número/ Localidad/ Provincia/ Código Postal/ País"
                  className={`form-control__round ${
                    errors.address && touched.address && "is-invalid"
                  }`}
                />
                {errors.address && touched.address && (
                  <FormFeedback className="d-block">
                    {errors.address}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Teléfono fijo"
                  className={`form-control__round ${
                    errors.phone && touched.phone && "is-invalid"
                  }`}
                />
                {errors.phone && touched.phone && (
                  <FormFeedback className="d-block">
                    {errors.phone}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="cellphone"
                  value={values.cellphone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Teléfono celular"
                  className={`form-control__round ${
                    errors.cellphone && touched.cellphone && "is-invalid"
                  }`}
                />
                {errors.cellphone && touched.cellphone && (
                  <FormFeedback className="d-block">
                    {errors.cellphone}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                  className={`form-control__round ${
                    errors.email && touched.email && "is-invalid"
                  }`}
                />
                {errors.email && touched.email && (
                  <FormFeedback className="d-block">
                    {errors.email}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="university"
                  value={values.university}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Universidad dónde se recibió"
                  className={`form-control__round ${
                    errors.university && touched.university && "is-invalid"
                  }`}
                />
                {errors.university && touched.university && (
                  <FormFeedback className="d-block">
                    {errors.university}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="average"
                  value={values.average}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Promedio"
                  className={`form-control__round ${
                    errors.average && touched.average && "is-invalid"
                  }`}
                />
                {errors.average && touched.average && (
                  <FormFeedback className="d-block">
                    {errors.average}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Select
                  className={`${
                    errors.document_type && touched.document_type
                      ? "select-error"
                      : ""
                  }`}
                  classNamePrefix="custom-select"
                  placeholder="Tipo de documento"
                  styles={customStyles}
                  options={docTypes}
                  value={values.document_type}
                  onChange={(value) => {
                    setFieldValue("document_type", value);
                  }}
                />
                {errors.document_type && touched.document_type && (
                  <FormFeedback className="d-block">
                    {errors.document_type}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  name="document_id"
                  value={values.document_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Número de Documento"
                  className={`form-control__round ${
                    errors.document_id && touched.document_id && "is-invalid"
                  }`}
                />
                {errors.document_id && touched.document_id && (
                  <FormFeedback className="d-block">
                    {errors.document_id}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <img src={AddFile} className="pr-1" alt="add icon" />
                <label htmlFor="upload-photo" className="add-file-input">
                  Subir documento
                </label>
                <input
                  className="d-block w-25"
                  type="file"
                  name="attachment"
                  id="upload-photo"
                  onChange={(e) => {
                    e.preventDefault();
                    let previewReader = new FileReader();
                    let file = e.target.files[0];

                    if (!file) {
                      setDisplayFrmGrp02(false);
                      return;
                    }

                    // Set the file name
                    setFileName(file.name);

                    const extRegex = /(?:\.([^.]+))?$/;
                    const nameRegex = /[^\.]+/;

                    const name = file.name.match(nameRegex)[0];
                    setFieldValue("file_name", name);

                    const ext = extRegex.exec(file.name)[0];
                    setFieldValue("file_ext", ext);

                    previewReader.readAsDataURL(file);

                    previewReader.onloadend = async () => {
                      const result = previewReader.result.split(";base64,");
                      setFieldValue("cv", result[1]);
                    };

                    setDisplayFrmGrp02(true);
                  }}
                />
                {fileName && <small className="d-block">{fileName} - <a href="javascript:;"  onClick={e => {
                   setFieldValue("attachment", null);
                   setFileName(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}
                {errors.attachment && touched.attachment && (
                  <FormFeedback className="d-block">
                    {errors.attachment}
                  </FormFeedback>
                )}
                <small className="d-block">
                  (Extensiones permitidas: .doc, .docx y .pdf).
                </small>
              </FormGroup>

              <FormGroup className={`frmGrp02 ${isVisibleFrmGrp02 ? "" : "hidden"}`}>
                <img src={AddFile} className="pr-1" alt="add icon" />
                <label htmlFor="upload-photo02" className="add-file-input">
                  Subir archivo nº 2
                </label>
                <input
                  className="d-block w-25 upload-photo"
                  type="file"
                  name="attachment02"
                  id="upload-photo02"
                  onChange={(e) => {
                    e.preventDefault();
                    let previewReader = new FileReader();
                    let file = e.target.files[0];

                    if (!file) {
                      setDisplayFrmGrp03(false);
                      return;
                    }

                    // Set the file name
                    setFileName02(file.name);
                    
                    const extRegex = /(?:\.([^.]+))?$/;
                    const nameRegex = /[^\.]+/;

                    const name = file.name.match(nameRegex)[0];
                    setFieldValue("file_name_02", name);

                    const ext = extRegex.exec(file.name)[0];
                    setFieldValue("file_ext_02", ext);
                    
                    previewReader.readAsDataURL(file);

                    previewReader.onloadend = async () => {
                      const result = previewReader.result.split(";base64,");
                      setFieldValue("attachment02", result[1]);
                    };

                    setDisplayFrmGrp03(true);
                  }}
                />
                {fileName02 && <small className="d-block">{fileName02} - <a href="javascript:;"  onClick={e => {
                   setFieldValue("attachment02", null);
                   setFileName02(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}
                {errors.attachment && touched.attachment && (
                  <FormFeedback className="d-block">
                    {errors.attachment}
                  </FormFeedback>
                )}
                <small className="d-block">
                  (Extensiones permitidas: .doc, .pdf, .jpg, .png).
                </small>
              </FormGroup>

              <FormGroup className={`frmGrp03 ${isVisibleFrmGrp03 ? "" : "hidden"}`}>
                <img src={AddFile} className="pr-1" alt="add icon" />
                <label htmlFor="upload-photo03" className="add-file-input">
                  Subir archivo nº 3
                </label>
                <input
                  className="d-block w-25 upload-photo"
                  type="file"
                  name="attachment03"
                  id="upload-photo03"
                  onChange={(e) => {
                    e.preventDefault();
                    let previewReader = new FileReader();
                    let file = e.target.files[0];

                    if (!file) {
                      setDisplayFrmGrp04(false);
                      return;
                    }

                    // Set the file name
                    setFileName03(file.name);
                    
                    const extRegex = /(?:\.([^.]+))?$/;
                    const nameRegex = /[^\.]+/;

                    const name = file.name.match(nameRegex)[0];
                    setFieldValue("file_name_03", name);

                    const ext = extRegex.exec(file.name)[0];
                    setFieldValue("file_ext_03", ext);
                    
                    previewReader.readAsDataURL(file);

                    previewReader.onloadend = async () => {
                      const result = previewReader.result.split(";base64,");
                      setFieldValue("attachment03", result[1]);
                    };

                    setDisplayFrmGrp04(true);
                  }}
                />

                {fileName03 && <small className="d-block">{fileName03} - <a href="javascript:;"  onClick={e => {
                   setFieldValue("attachment03", null);
                   setFileName03(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}

                {errors.attachment && touched.attachment && (
                  <FormFeedback className="d-block">
                    {errors.attachment}
                  </FormFeedback>
                )}
                <small className="d-block">
                  (Extensiones permitidas: .doc, .pdf, .jpg, .png).
                </small>
              </FormGroup>

              <FormGroup className={`frmGrp04 ${isVisibleFrmGrp04 ? "" : "hidden"}`}>
                <img src={AddFile} className="pr-1" alt="add icon" />
                <label htmlFor="upload-photo04" className="add-file-input">
                  Subir archivo nº 4
                </label>
                <input
                  className="d-block w-25 upload-photo"
                  type="file"
                  name="attachment04"
                  id="upload-photo04"
                  onChange={(e) => {
                    e.preventDefault();
                    let previewReader = new FileReader();
                    let file = e.target.files[0];

                    if (!file) {
                      return;
                    }

                    // Set the file name
                    setFileName04(file.name);
                    
                    const extRegex = /(?:\.([^.]+))?$/;
                    const nameRegex = /[^\.]+/;

                    const name = file.name.match(nameRegex)[0];
                    setFieldValue("file_name_04", name);

                    const ext = extRegex.exec(file.name)[0];
                    setFieldValue("file_ext_04", ext);
                    
                    previewReader.readAsDataURL(file);

                    previewReader.onloadend = async () => {
                      const result = previewReader.result.split(";base64,");
                      setFieldValue("attachment04", result[1]);
                    };

                  }}
                />

                {fileName04 && <small className="d-block">{fileName04} - <a href="javascript:;"  onClick={e => {
                   setFieldValue("attachment04", null);
                   setFileName04(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}

                {errors.attachment && touched.attachment && (
                  <FormFeedback className="d-block">
                    {errors.attachment}
                  </FormFeedback>
                )}
                <small className="d-block">
                  (Extensiones permitidas: .doc, .pdf, .jpg, .png).
                </small>
              </FormGroup>

              <SubmitBtn
                color="orange"
                className="mt-4"
                text="Enviar"
                loading={isSubmitting}
              />

              <p className="d-block mt-4">
                IMPORTANTE: Sí usted cuenta con la documentación que se solicita
                a continuación, cárguela. De lo contrario usted tiene tiempo de
                enviarla a{" "}
                <a
                  className="links-inner-faqs"
                  href="mailto: residenciasclincamedica@altasalud.com.ar"
                >
                  residenciasclincamedica@altasalud.com.ar
                </a>{" "}
                o{" "}
                <a
                  href="mailto: residenciacardiologia@altasalud.com.ar"
                  className="links-inner-faqs"
                >
                  residenciacardiologia@altasalud.com.ar
                </a>
                .
              </p>
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  formTypes: state.formTypes.formTypes,
  formTypesLoading: state.formTypes.loading,
  docTypes: state.auth.types.data,
  docTypesLoading: state.auth.types.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    sendMedicalResidenceMail,
    getDocTypes,
  })(ResidenciasForm)
);
