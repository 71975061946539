import React from "react";
import { Link, withRouter } from "react-router-dom";

const LinkActive = props => {
  let isActive;
  const arrPathName = props.location.pathname.split("/");

  if (arrPathName.length > 3) {
    const parentPath = `/panel/${arrPathName[2]}`;
    if (parentPath === props.to) {
      isActive = true;
    }
  } else {
    isActive = props.location.pathname === props.to;
  }

  return (
    <Link to={props.to} className={`${isActive ? "active" : ""}`}>
      {props.children}
    </Link>
  );
};

export default withRouter(LinkActive);
