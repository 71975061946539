import React from "react";

import { connect } from "react-redux";
import { searchPharmacies } from "../redux/actions/common";

import LinkActive from "./LinkActive";

// Images
import Arrow from "../assets/img/arrow-derecha-planes.svg";
import DatosPersonales from "../assets/img/dd_personales.svg";
import Turnos from "../assets/img/turnos.svg";
import Imagenes from "../assets/img/imagenes.svg";
import Facturas from "../assets/img/facturas.svg";
import Tramites from "../assets/img/tramites.svg";
import Medicamentos from "../assets/img/medicamentos.svg";
import Autorizaciones from "../assets/img/autorizaciones.svg";
import Contacto from "../assets/img/contacto.svg";
import Laboratorio from "../assets/img/laboratorio.svg";

const Sidebar = ({ isOpen, searchPharmacies, user }) => {
  return (
    <aside id="sidebar" className={`${isOpen ? "active" : false}`}>
      <div className="sidebar-header">
        <button
          type="button"
          id="sidebarCollapse"
          className={`btn__arrow-toggle ${isOpen ? "open" : "close"}`}
          onClick={() => searchPharmacies()}
        >
          <img src={Arrow} alt="arrow right" />
        </button>
        <h5 className="header__menu-dashboard">
          Mi cuenta
          <span className="menu-dashboard__user-name">
            ¡Hola {user && user.full_name}!
          </span>
        </h5>
      </div>
      <ul className="list-unstyled components menu-dashboard__ul">
        <li>
          <LinkActive to="/panel">
            <img
              src={DatosPersonales}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Datos personales</span>
          </LinkActive>
        </li>

        <li>
          <LinkActive to="/panel/turnos">
            <img
              src={Turnos}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Turnos</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/laboratorios">
            <img
              src={Laboratorio}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Laboratorios</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/imagenes">
            <img
              src={Imagenes}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Imagenes</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/facturas">
            <img
              src={Facturas}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Facturas</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/tramites">
            <img
              src={Tramites}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Trámites</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/medicamentos">
            <img
              src={Medicamentos}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Medicamentos</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/autorizaciones">
            <img
              src={Autorizaciones}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Autorizaciones</span>
          </LinkActive>
        </li>
        <li>
          <LinkActive to="/panel/contacto">
            <img
              src={Contacto}
              alt="datos personales"
              className="menu-dashboard__icons"
            />
            <span className="collapsed-names">Contacto</span>
          </LinkActive>
        </li>
      </ul>
    </aside>
  );
};

const mapStateToProps = state => ({
  isOpen: state.common.sidebarOpen,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  {
    searchPharmacies
  }
)(Sidebar);
