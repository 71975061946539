import React from "react";
import PageWrapper from "../PageWrapper";

import { Col } from "reactstrap";
import PageTitle from "../PageTitle";

const Content = () => {
  return (
    <PageWrapper>
      <Col xs="12">
        <PageTitle>1. Objetivos</PageTitle>
        <p className="mb-5">
          Otorgar una Cobertura para la protección, promoción y recuperación de
          la Salud de los asociados de acuerdo a las condiciones establecidas en
          este plan y según lo que establece el presente reglamento. La
          Cobertura del PMO (Programa Médico Obligatorio), se realizará de
          acuerdo a la reglamentación particular de cada plan de cobertura
          ofrecida con las variaciones que contemple cada producto, y en los
          lugares prefijados en la Cartilla Médica respectiva. Previa
          autorización, por parte de la Auditoría de Santa Salud S.A.
        </p>
        <PageTitle>2. SISTEMA DE COBERTURA</PageTitle>
        <p className="mb-5">
          Los beneficios médico asistenciales de Santa Salud S.A., se brindan
          exclusivamente bajo la modalidad de sistema cerrado. Las relaciones
          entre Santa Salud S.A. para con sus afiliados se regirá por la
          Solicitud de Ingreso respectiva, por las modalidades establecidas de
          antemano por Santa Salud S.A. y por este Reglamento y las posibles
          modificaciones que pudieran implementarse. A tal efecto Santa Salud
          S.A. se obliga a comunicar los cambios producidos en el mismo a cada
          uno de sus afiliados. Es responsabilidad de los afiliados mantenerse
          informados de esas modificaciones, no pudiendo de esta manera alegar
          ignorancia al respecto.
        </p>
        <PageTitle>3. CONDICIONES DE INGRESO</PageTitle>
        <p className="mb-5">
          Podrán ingresar a Santa Salud S.A. en calidad de afiliados titulares,
          todas aquellas personas mayores de 18 años legalmente capaces, con su
          grupo familiar. Pueden formar parte de dicho grupo familiar el cónyuge
          del titular y el pariente de cada miembro de la pareja hasta el
          segundo grado de consanguineidad (hijos, padres, nietos, hermanos y
          abuelos), y los menores de 22 años de edad hasta el tercer grado de
          consanguineidad (bisnietos, sobrinos y tíos). Los hijos recién nacidos
          podrán ser incorporados por el titular del grupo familiar, dentro de
          las primeras 48 horas hábiles de su nacimiento, presentando el
          certificado médico expedido por el neonatólogo o pediatra
          interviniente, donde conste su estado de salud. En caso de realizarse
          el trámite dentro del plazo mencionado, el recién nacido asumirá la
          antigüedad de la madre, siempre que hubiese continuidad en el pago de
          las cuotas mensuales y el estado de la cuenta esté al día. Cuando la
          incorporación se produzca fuera del tiempo estipulado, el recién
          nacido deberá cumplir sus propios períodos de carencia. Todos los
          beneficios con relación a la maternidad rigen exclusivamente para el
          titular o cónyuge del titular que figure como tal en la Solicitud de
          Ingreso. Cuando un integrante del grupo familiar no comprendido en la
          cobertura de parte presente su condición de embarazo, deberá conformar
          una asociación como titular dentro de los 60 (sesenta) días corridos
          de su nuevo estado fisiológico a fin de obtener la cobertura del parto
          y mantener su antigüedad inicial. Se establece como condición
          necesaria y absoluta el pago del equivalente al plan por matrimonio,
          en el caso mencionado con anterioridad
        </p>
        <PageTitle>4. REQUISITOS PARA EL INGRESO</PageTitle>
        <p className="mb-5">
          Todos los interesados en suscribir su adhesión a Santa Salud S.A.,
          deberán suscribir de puño y letra la correspondiente Solicitud de
          Ingreso, que además tendrá carácter de Declaración Jurada en la que
          constarán todos los datos personales, como así también de las personas
          que éste desee incorporar como adicionales. Junto a la solicitud de
          afiliación se acompañará una Declaración Jurada de Salud la cual, por
          motivos legales, también deberá ser confeccionada de puño y letra del
          interesado, señalando en la misma las incapacidades físicas,
          psíquicas, enfermedades, lesiones, accidentes sufridos, tratamientos
          y/u operaciones que se le hubiesen practicado o a los que se hallasen
          expuestos y/o sometidos. Es condición indispensable para la
          incorporación que los datos sean completos, veraces y exactos. El
          solicitante es personalmente responsable por sus datos y los de su
          grupo familiar y no podrá aducir ignorancia como eximente de esta
          responsabilidad. Santa Salud S.A., se reserva el derecho de aceptar o
          rechazar la incorporación dentro de los 60 (sesenta) días corridos de
          presentada la documentación requerida para el ingreso, sin necesidad
          de invocar causa alguna. Supone esto el ejercicio del derecho
          constitucional de admisión de Santa Salud S.A. Prestando conformidad
          el adherente de que no se trata de motivos discriminatorios sino
          simplemente de reglamentación privada. Lo cual se manifiesta en este
          acto de aceptación de este Reglamento y Condiciones Generales. Al
          mismo tiempo Santa Salud S.A. se reserva el Derecho de la ampliación
          de la información solicitada (resúmenes de historias clínicas,
          exámenes físicos, etc.), en cuyo caso el interesado tendrá un plazo de
          30 (treinta) días para cumplimentar los mismos. En el caso de rechazo
          por insuficiencia y/o falsedad de la información suministrada, el
          postulante perderá el derecho a reclamar las sumas abonadas hasta ese
          momento, ya que acepta ésta debidamente informado al respecto,
          aceptando dicha condición. En aquellos casos en que por venir de otras
          instituciones de medicina privada Santa Salud S.A. acepta la
          afiliación dando por cumplidos los plazos de carencia, serán éstas
          únicamente las que en las tablas de beneficios sean de carecía menor a
          180 días. Todas aquellas que en dicha tabla figuren con un plazo mayor
          de carencia de 180 días, deberán ser cumplidas indefectiblemente para
          ser cubiertas por Santa Salud S.A.
        </p>
        <PageTitle>5. CREDENCIAL PERSONAL</PageTitle>
        <p className="mb-5">
          Una vez aceptado el ingreso, cada afiliado titular y su grupo
          familiar, recibirán las credenciales que los identifican como
          afiliados a Santa Salud S.A Las mismas, a través de medios de
          almacenamiento magnético, contendrán toda la información necesaria
          para la utilización del Sistema. Las credenciales son de carácter
          personal e intransferible, estando penada la utilización de las mismas
          por personas no afiliadas. Ante la pérdida o robo de éstas, el titular
          deberá comunicar de inmediato el hecho por escrito, haciéndose
          responsable por las consecuencias si omitiese hacerlo. Toda vez que el
          afiliado requiera hacer uso de las prestaciones brindadas por Santa
          Salud S.A., deberá presentar la credencial personal habilitante y una
          identificación personal para corroborar su identidad.
        </p>
        <PageTitle>6. VIGENCIA DEL SERVICIO</PageTitle>
        <p className="mb-5">
          Todos los contratos de adhesión a Santa Salud S.A., finalizan el 31 de
          diciembre de cada año y se renuevan automáticamente por el plazo de 1
          (un) año a partir del 1 de enero del año siguiente, salvo que fuese
          denunciado por cualquiera de las partes con 10 (diez) días hábiles de
          antelación a su finalización. Para el primer año de ingreso, los
          adherentes que ingresaran con una antelación mayor de 6 (seis) meses
          al día de finalización del contrato, el mismo terminará el 31 de
          diciembre del año siguiente, aplicándose para todos la forma de
          renovación automática aquí establecida.
        </p>
        <PageTitle>7. OBLIGACIONES DEL TITULAR</PageTitle>
        <p className="mb-5">
          El afiliado deberá abonar por adelantado las cuotas mensuales, dentro
          del período del 1 al 15 de cada mes. Se posibilitará la cancelación de
          los importes mensuales por cuotas mediante: débitos automáticos de
          cuentas corrientes, cajas de ahorro en pesos, tarjetas de crédito,
          tickets, cheques personales, cobro domiciliario, etc.). Vencido el
          plazo de pago se aplicarán los intereses correspondientes de acuerdo a
          la tasa fijada por el BCRA para descubiertos en cuentas corrientes, a
          dicho momento. Es obligación exclusiva del afiliado comunicar de
          inmediato toda modificación que se produzca en la integración de su
          grupo. Todo cambio y/o modificación que se desee introducir en la
          vinculación con Santa Salud S.A., deberá ser solicitada exclusivamente
          por el afiliado titular, el cual es responsable de las obligaciones
          emergentes de dicha vinculación y solidario por los perjuicios que
          pudieren ocasionar las personas por él incorporadas. En caso de
          desvinculación contractual, el afiliado deberá restituir a Santa Salud
          S.A. las credenciales y/o documentación que faculten la accesibilidad
          al Sistema, así como también responder ante los gastos ocasionados
          durante los períodos impagos o carentes de cobertura. Los incrementos
          en las cuotas no necesitarán de la conformidad previa de los
          asociados. Vencidos los plazos indicados ut-supra el afiliado incurre
          en mora automática, por el sólo transcurso del tiempo y sin necesidad
          de previo requerimiento judicial y/o extrajudicial de ninguna índole.
          Como consecuencia de ello pierde el derecho de utilizar las
          prestaciones del Sistema. Santa Salud S.A. se reserva el derecho a
          reclamar eventuales erogaciones efectuadas por la utilización de los
          servicios durante el período en que le asociado ya se hallase al día
          en sus pagos. El pago posterior no saneará el período durante el cual
          se encontró en mora, y no hará resurgir el derecho a la cobertura
          durante la vigencia de la mora aunque no se haga reserva alguna a tal
          efecto, al momento de recibir el mencionado pago. La cancelación de la
          cuota mensual fuera de tiempo sufrirá un recargo, de acuerdo a lo
          establecido anteriormente. Al producirse el incumplimiento en el pago
          de 2 (dos) cuotas consecutivas o no en los plazos convenidos, el
          asociado y su grupo familiar perderán la antigüedad acumulada,
          debiendo cumplimentar una vez saneada la mora los nuevos períodos de
          carencia. Dicha pérdida es automática y no quedará condonada por el
          posterior pago, salvo que la Dirección de Santa Salud S.A. así lo
          disponga. El asociado se obliga e informa en este acto a cancelar las
          sumas emergentes por las cuotas impagas y el importe de los servicios
          que hubieren sido utilizados durante la mora, en un plazo no mayor a
          las 72 horas de ser intimado fehacientemente, bajo apercibimiento de
          obtener su cobro por vía judicial, con más sus intereses y costas
          correspondientes. La vinculación con el afiliado titular y/o sus
          incorporados podrá ser resuelta en cualquier momento cuando mediase
          causa justificada y especialmente en los siguientes casos: - Mal trato
          de palabra o de hecho al personal o a profesionales adheridos, aunque
          no mediase reiteración. La resolución del convenio de este supuesto,
          sólo se aplicará respecto del autor del hecho. - Falta de pago durante
          el término de 3 (tres) meses consecutivos de cuotas y/o de importes
          facturados al afiliado, por aranceles o prestaciones no cubiertas por
          Santa Salud S.A. si el titular no cancela la deuda dentro de los diez
          días posteriores a la intimación realizada por Santa Salud al efecto.
          - Falta de denuncia o extravío de la credencial de Santa Salud S.A.
          del afiliado titular o de sus incorporados, o utilización de la misma
          por terceros. - Demora o incumplimiento en el pago a institutos,
          instituciones asistenciales, laboratorios y otras entidades adheridas,
          por prestaciones no cubiertas y/o excedentes de los topes estipulados
          por Santa Salud S.A. - Uso abusivo de los servicios, según criterio de
          Auditoría Médica de Santa Salud S.A. -Requerir cualquier servicio
          comprendido en los beneficios de los planes ofrecidos por Santa Salud
          S.A. para cualquier otra persona no adherida al Sistema. Cualquier
          actitud intencional del afiliado que derive o pueda ocasionar
          perjuicio a la empresa. En los supuestos contemplados, el afiliado
          deberá indemnizar a Santa Salud S.A. por los perjuicios que le hubiese
          ocasionado, restituyéndole los importes de los gastos generados en
          dichos supuestos, actualizados conforme a los aranceles vigentes al
          momento de su indemnización, más los intereses que correspondan.
        </p>
        <PageTitle>8. RESPONSABILIDAD DE SANTA SALUD S.A.</PageTitle>
        <p className="mb-5">
          Teniendo en cuenta la libertad de elección de los profesionales entre
          los asignados a su Red Prestacional, Santa Salud S.A no se
          responsabiliza por los daños y/o perjuicios que estos causasen como
          consecuencia de la impericia, imprudencia, negligencia y/u otra forma
          de culpa. La Empresa exige a los profesionales integrantes de su Red,
          mantener vigente y exhibir un seguro de mala praxis a tales efectos,
          como así también de cualquier información curricular de los
          profesionales adheridos. Se informa al titular del Servicio que Santa
          Salud S.A. actúa como intermediario en la prestación de servicios de
          terceros.
        </p>
        <PageTitle>9. PRESTADORES</PageTitle>
        <p className="mb-5">
          Los afiliados a Santa Salud S.A., deberán cumplir con las
          disposiciones, normas y/o reglamentos particulares establecidos para
          cada prestador. Asimismo deberán respetar los turnos solicitados para
          su atención. Cuando hubiese imposibilidad de su cumplimiento, deberán
          comunicar con antelación suficiente las cancelaciones de las citas
          concertadas.
        </p>
        <PageTitle>10. DOMICILIO DEL TITULAR</PageTitle>
        <p className="mb-5">
          El titular constituirá su Domicilio en el detallado en la Solicitud de
          Ingreso, siendo su obligación mantenerlo actualizado en los registros
          obrantes en poder de Santa Salud S.A. De no hacerlo, se considerará
          válida toda comunicación que le fuera enviada al Domicilio indicado en
          la Solicitud de Ingreso o al último informado.
        </p>
        <PageTitle>11. SUSTITUCIÓN DEL AFILIADO TITULAR</PageTitle>
        <p className="mb-5">
          Ante el fallecimiento o desvinculación del Titular, el Convenio
          caducará de pleno derecho excepto que uno de los miembros del grupo
          familiar, legalmente capaz, asuma la condición de tal. Toda
          sustitución del afiliado Titular deberá infamarse fehacientemente a
          Solicitud de Ingreso, antes del día 20 del mes en que se produzca la
          modificación. Sin perjuicio de ello y ante el fallecimiento del
          Titular; Santa Salud S.A garantizará a los integrantes del Grupo
          Familiar Primario la Cobertura del Programa Médico Obligatorio por un
          término de dos meses contados desde su fallecimiento sin obligación de
          efectuar pago alguno, vencido dicho plazo, cualquier miembro que reúna
          las condiciones, podrá optar por la continuidad, para lo cual deberá
          constituirse como titular, celebrándose un nuevo contrato pero
          manteniéndose la antigüedad de los beneficiarios.
        </p>
        <PageTitle>12. SERVICIOS NO RECONOCIDOS POR SANTA SALUD S.A.</PageTitle>
        <p className="mb-5">
          Se denominan así a aquellos Servicios que corresponden a los
          siguientes ítems: a) Servicios utilizados fuera de contexto de este
          reglamento; b) Servicios no contemplados dentro de los beneficios del
          plan; c) Gastos producidos durante la mora en el pago de cuotas; d)
          Incumplimiento de períodos de espera (carencias); e) Prestaciones
          opcionales sin su pago previo; y f) Servicios no cubiertos. En caso de
          utilización de Servicios No Reconocidos, los mismos deberán ser
          abonados dentro de los 10 (diez) días de haber sido reclamados, con
          independencia de las sumas adeudadas por cualquier otro concepto.
        </p>
        <PageTitle>13. PREEXISTENCIAS</PageTitle>
        <p className="mb-5">
          Santa Salud S.A. considera a éstas como toda patología, enfermedad,
          dolencia y/o secuela de tratamientos efectuados, que haya padecido o
          posea el postulante a afiliado Titular y/o integrante del grupo
          familiar previo a su ingreso, sea o no conocida o declarada por él a
          los mismos. La detección de preexistencia/s no conocida/s por Santa
          Salud S.A. a través de antecedentes registrados en documentación
          clínica o por evaluaciones médicas que decida efectuar, justifica su
          inclusión como tal en el contrato vinculante. LA ATENCIÓN Y/O
          TRATAMIENTO DE PREEXISTENCIA/S SERÁ A EXCLUSIVO CARGO DEL AFILIADO,
          salvo expreso consentimiento de Santa Salud S.A. En ese caso, con el
          correspondiente detalle de la cobertura acordada que será comunicada
          al afiliado que firmará de conformidad, pudiéndose en este caso en
          particular efectuar aclaraciones producto de mutuo acuerdo entre las
          partes (Santa Salud S.A. y el aspirante a afiliado), Santa Salud S.A.
          no reconocerá en ningún caso antigüedad para la preexistencia. En caso
          de ocultamiento de preexistencia/s en la Declaración Jurada anexa a la
          Solicitud de Ingreso, antecedentes de salud o falsedad en los datos de
          la misma, Santa Salud S.A. tendrá lugar a considerar la baja del
          afiliado y a requerir el resarcimiento económico correspondiente, si
          hubiese existido utilización de los servicios.
        </p>
        <PageTitle>14. EXCLUSIONES</PageTitle>
        <p>
          Quedan excluidos de la cobertura de los planes de Santa Salud S.A.,
          todos los servicios y prácticas enunciados en el siguiente listado: a)
          Homeopatía, acupuntura y otras prácticas no reconocidas oficialmente
          por el Ministerio de Salud. b) Lesiones o enfermedades provocadas por
          siniestros, guerras declaradas o no, riñas, tumultos populares,
          guerrillas, rebelión, huelgas o terrorismo. c) Lesiones o enfermedades
          derivadas de conductas ilícitas (intoxicaciones auto provocadas,
          alcoholismo, toxicomanías, tentativas de suicidio), prácticas ilegales
          y accidentes sufridos en estado de ebriedad o bajo la influencia de
          narcóticos, estupefacientes o similares, o en estado de enajenación
          mental. d) Accidentes de trabajo y enfermedades profesionales. e)
          Cirugía estética o cosmética no reparadora. Cirugía de los vicios de
          refracción. f) Tratamientos termales o internaciones geriátricas. g)
          Provisión de anteojos, lentes intra o extra oculares y audífonos. h)
          Complicaciones derivadas de la atención efectuada por profesionales no
          pertenecientes a los planteles reconocidos por el Plan. i) Provisión
          de sangre o plasma, salvo con reposición. j) Provisión de material
          descartable, medicamentos y anestesia en ambulatorio o de uso en
          Domicilio. k) Gastos de acompañante en internación excepto la
          internación pediátrica o neonatológica que incluye a la madre con el
          niño (hasta 10 años). l) Gastos extras de internación no relacionados
          con las atenciones del paciente (comunicaciones, servicios
          diferenciales, bebidas, etc.). No incluido en los módulos. m)
          Adelgazamiento estético. n) Prácticas relacionadas con la utilización
          de métodos anticonceptivos. o) Reimplantes y/o trasplantes de células,
          tejidos y órganos. p) Complicaciones o secuelas que resulten de la
          negligencia del afiliado, por abandono de tratamiento o por no
          requerir asistencia en el momento oportuno. q) Necropsias. Excepto las
          que explícitamente autorice la Dirección Médica de esta Institución.
          r) Esterilidad (estudio pareja estéril). s) Cámara hiperbática,
          prótesis y ortesis, salvo las ofrecidas en el Nomenclador dispuesto
          por cada Plan Médico de Santa Salud S.A. t) Secuelas de prácticas
          ilegales. u) Isótopos y material radiactivo, en prácticas
          ambulatorias. Todas las prácticas aquí mencionadas se pondrán a
          consideración de la Auditoría Médica de Santa Salud S.A., la cual
          ofrecerá para tales casos el acceso a valores preferenciales a todo
          aquel afiliado que requiera algún ítem enumerado.
        </p>
      </Col>
    </PageWrapper>
  );
};

export default Content;
