import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Contacto/Content";

import ImageBanner from "../assets/img/contacto.jpg";

const Contacto = () => (
  <>
    <Tags 
      title="Contactanos" 
      description="Todas tus consultas serán contestadas." 
      keywords="contactanos, mensaje, aun no sos socio, asociate, servicio de atencion al cliente, consulta, pregunta, mas informacion" 
      canonical="/contacto" />
    <Banner
      title="CONTACTO"
      subtitle="No dudes en enviarnos tu consulta, estamos para ayudarte"
      image={ImageBanner}
      height="ct"
    />
    <Content />
  </>
);

export default Contacto;
