import React from "react";
import PropTypes from "prop-types";
import { Pagination, PaginationItem, Spinner } from "reactstrap";

const Paginator = ({
  currentPage,
  first,
  last,
  next,
  prev,
  handlePaginator,
  loading,
  center
}) => {
  let firstPage = first && parseInt(new URL(first).searchParams.get("page"));
  let lastPage = last && parseInt(new URL(last).searchParams.get("page"));

  if (loading) {
    return (
      <div className="text-right">
        <Spinner size="sm" />
      </div>
    );
  }

  return (
    <Pagination
      className={`pagination-wrapper ${center ? "paginator-center" : ""}`}
    >
      {firstPage !== currentPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(firstPage)}
          >
            First
          </button>
        </PaginationItem>
      )}

      {currentPage - 4 >= firstPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage - 4)}
          >
            {currentPage - 4}
          </button>
        </PaginationItem>
      )}

      {currentPage - 3 >= firstPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage - 3)}
          >
            {currentPage - 3}
          </button>
        </PaginationItem>
      )}

      {currentPage - 2 >= firstPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage - 2)}
          >
            {currentPage - 2}
          </button>
        </PaginationItem>
      )}

      {currentPage - 1 >= firstPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage - 1)}
          >
            {currentPage - 1}
          </button>
        </PaginationItem>
      )}

      {currentPage && (
        <PaginationItem>
          <button className={`page-link ${currentPage ? "active" : ""}`}>
            {currentPage}
          </button>
        </PaginationItem>
      )}

      {currentPage + 1 <= lastPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage + 1)}
          >
            {currentPage + 1}
          </button>
        </PaginationItem>
      )}

      {currentPage + 2 <= lastPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage + 2)}
          >
            {currentPage + 2}
          </button>
        </PaginationItem>
      )}

      {currentPage + 3 <= lastPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage + 3)}
          >
            {currentPage + 3}
          </button>
        </PaginationItem>
      )}

      {currentPage + 4 <= lastPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(currentPage + 4)}
          >
            {currentPage + 4}
          </button>
        </PaginationItem>
      )}

      {lastPage !== currentPage && (
        <PaginationItem>
          <button
            className={`page-link`}
            onClick={() => handlePaginator(lastPage)}
          >
            Last
          </button>
        </PaginationItem>
      )}
    </Pagination>
  );
};

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  first: PropTypes.string,
  prev: PropTypes.string,
  next: PropTypes.string,
  handlePaginator: PropTypes.func.isRequired
};

export default Paginator;
