import {
  PHARMACIES_LOADING,
  GET_PHARMACIES_SUCCESS,
  GET_PHARMACIES_FAILURE,
  SET_PHARMACY_PAGE,
  SET_PROVINCE_PHARMACY_ID,
  SET_AREA_PHARMACY_ID,
  SET_LOCATIONS_PHARMACY_ID,
  RESET_PHARMACIES,
  // COMMENT: ADD THIS LINE
  SET_PHARMACIES_NAME,
} from "../actions/types/pharmacies";

const initialState = {
  pharmacies: [],
  loading: false,
  markers: [],
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    currentPage: 1,
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    location_id: null,
    area_id: null,
    province_id: null,
    pharmacies_name: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PHARMACIES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_PHARMACIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        pharmacies: [...action.payload.pharmacies],
        markers: [...action.payload.markers],
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          currentPage: action.payload.meta.currentPage,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_PHARMACIES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_PHARMACY_PAGE: {
      return {
        ...state,
        loading: false,
        meta: {
          ...state.meta,
          currentPage: action.payload.page
        },
        filters: {
          ...state.filters,
          page: action.payload.page
        }
      };
    }

    case SET_PROVINCE_PHARMACY_ID: {
      return {
        ...state,
        filters: {
          ...state.filters,
          province_id: action.payload.value,
          page: 1
        }
      };
    }

    case SET_AREA_PHARMACY_ID: {
      return {
        ...state,
        filters: {
          ...state.filters,
          area_id: action.payload.value,
          page: 1
        }
      };
    }

    case SET_LOCATIONS_PHARMACY_ID: {
      return {
        ...state,
        filters: {
          ...state.filters,
          location_id: action.payload.value,
          page: 1
        }
      };
    }

  // COMMENT: START CODE 

  case SET_PHARMACIES_NAME: {
    return {
      ...state,
      filters: {
        ...state.filters,
        pharmacies_name : action.payload.value,
        page: 1,
      },
    };
  }
// COMMENT: END CODE 

    

    case RESET_PHARMACIES: {
      return {
        ...state,
        ...initialState
      };
    }

    default:
      return state;
  }
};
