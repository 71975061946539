import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_IMAGENES,
  GET_IMAGENES_SUCCESS,
  GET_IMAGENES_FAILURE,
  SET_IMAGENES_PAGE,
  RESET_IMAGENES
} from "./types/imagenes";
import { LogOut } from "./auth";
import { store } from "../index";
import moment from "moment";

export const getImagenes = filters => async dispatch => {
  dispatch({ type: LOADING_IMAGENES });

  const { user } = store.getState().auth;

  const lastName = user.full_name.split(" ");

  const id = user.personal_id;
  const formatedDate = moment(user.born_date)
    .format("YYYY-MM-DD")
    .split("-")
    .join("");

  try {
    const baseUrl = `${API_URL}/api/web/moreau/execute/${lastName[0]}/${id}/${formatedDate}`;

    let URL = `${baseUrl}`;

    const response = await axios.get(URL);

    if (response.status === 200) {
      dispatch({
        type: GET_IMAGENES_SUCCESS,
        payload: {
          imagenes: response.data
        }
      });
    }

    return response.status;
  } catch (error) {
    dispatch({ type: GET_IMAGENES_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0007)");
    }
    return error;
  }
};

export const setPage = page => ({
  type: SET_IMAGENES_PAGE,
  payload: {
    page
  }
});

export const resetImagenes = () => ({
  type: RESET_IMAGENES
});
