import React from "react";
import { Col } from "reactstrap";

import PageWrapper from "../PageWrapper";
import ContactForm from "./ContactForm";

import MapIcon from "../../assets/img/maplocation.svg";
import MailIcon from "../../assets/img/mail.svg";

const Content = () => (
  <PageWrapper>
    <Col xs={12} sm={6}>
      <h5 className="contact__title">Dirección</h5>
      <p className="contatc__p">
        <img src={MapIcon} alt="map icon" className="pr-1" />
        Av. Gaona 3519/27, C1416DSX CABA
      </p>

      <h5 className="contact__title">Teléfonos</h5>
      <p className="contatc__p">
        <span className="semi-bold-font">Centro de atención al cliente:</span>{" "}
        4588-5900
        <br />
        <span className="semi-bold-font">
          Urgencias y emergencias 24hs:
        </span>{" "}
        0810-999-2628
        <br />
        <span className="semi-bold-font">Clínica Alta Salud:</span> 4588-5800
        <br />
        <span className="semi-bold-font">Ventas:</span> 0800-333-2582
        <br />
      </p>

      <h5 className="contact__title">Email</h5>
      <a className="contatc__p d-block" href="mailto:info@altasalud.com.ar">
        <img
          src={MailIcon}
          alt=""
          className="pr-1 centros-medicos__icons-info"
        />
        info@altasalud.com.ar
      </a>
      <h5 className="contact__title">¿Querés ser socio?</h5>
      <p className="contatc__p">
        Podes ser socio llamando al 0800-333-2582 o en cualquiera de nuestras
        sucursales, además podes enviarnos un mensaje mediante el formulario a
        continuación seleccionando como motivo de contacto “Quiero asociarme”.
      </p>
    </Col>
    <Col xs={12} sm={6}>
      <ContactForm />
    </Col>
  </PageWrapper>
);

export default Content;
