import React, { useEffect, useState } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { getMedicalServices } from "../redux/actions/medicalServices";
import { customStyles } from "./styles/common";

const MedicalSSelect = ({ getMedicalServices, data, loading, onSelect }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getMedicalServices();
    };

    fetchData();
  }, [getMedicalServices]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="custom-select"
      options={data}
      value={value}
      onChange={value => {
        setValue(value);
        onSelect(value.value);
      }}
      placeholder="Servicios Médicos"
      styles={customStyles}
      noOptionsMessage={() => "No se encontraron datos."}
      isLoading={loading}
      clearable={false}
    />
  );
};

const mapStateToProps = state => ({
  data: state.medicalServices.data,
  loading: state.medicalServices.loading
});

export default connect(mapStateToProps, {
  getMedicalServices
})(MedicalSSelect);
