import React from "react";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/tramites.svg";
import PedidoCredencialForm from "../PedidoCredencialForm";

const PedidoCredencial = () => {
  return (
    <>
      <PanelTitle icon={Icon}>Trámites / Pedido de Credencial </PanelTitle>
      <Row className="mx-0 mt-5">
        <Col xs={12} md={6} className="pb-4">
          <h5>Por favor complete los datos a continuación:</h5>
          <PedidoCredencialForm />
        </Col>
      </Row>
    </>
  );
};

export default PedidoCredencial;
