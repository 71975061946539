import React, { useCallback, useState, useEffect } from "react";
import { Row, Form, Col } from "reactstrap";
import { Spinner } from "reactstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect, ReactReduxContext, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  searchMedicalEntities,
  setMedicalServiceId,
  setMedicalSpecialtyId,
  setLocationId,
  setAreaId,
  setProvinceId,
  // COMMENT: ADD LINE Dario
  setMedicalNameEntity,
} from "../../redux/actions/medicalEntity";

import Button from "../SubmitBtn";
import MSSelect from "../MedicalSSelect";
import MSpecialties from "../SpecialtiesSelect";
import PSelect from "../ProvincesSelect";
import ASelect from "../AreasSelect";
import LSelect from "../LocationsSelect";

// COMMENT: ADD LINE Dario
import MedicalNameSelect from "../MedicalNameSelect";

var medicalServiceId_save="";
var medicalServiceIdName_save="";
var medicalSpecialtyId_save="";
var locationId_save="";
var areaId_save="";
var provinceId_save="";
var medicalNameEntity_save="";

var urlCartillamedica = "https://altasalud.com.ar/cartilla-medica?";
// var urlCartillamedica = 'https://preprod.altasalud.com.ar/cartilla-medica?';
// var urlCartillamedica = 'http://localhost:3000/cartilla-medica?';

const Searcher = ({
  loading,
  filters,
  searchMedicalEntities,
  setMedicalServiceId,
  setMedicalServiceIdName,
  setMedicalSpecialtyId,
  setLocationId,
  setAreaId,
  setProvinceId,
  // COMMENT: ADD LINE
  setMedicalNameEntity,
  pageLoadedOnce,
}) => {
  const [values, setValue] = useState({
    area_id: null,
    location_id: null,
  });

  const { useContext } = React;
  const { store } = useContext(ReactReduxContext);

  const usuario_data = useSelector((state) => state.auth.user);

  const fetchData = useCallback(async () => {
    console.log("FILTERS--->", filters);
    filters.user_data = usuario_data;
    await searchMedicalEntities(filters, true);
  }, [filters, searchMedicalEntities]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await fetchData();
  };

  const handleSetServiceIdName = (id) => {
    setMedicalServiceIdName(id);

    medicalServiceIdName_save = id;
  };

  const handleSetServiceId = (id) => {
    setMedicalServiceId(id);
    medicalServiceId_save = id;
  };

  const handleSpecialtyId = (id) => {
    setMedicalSpecialtyId(id);

    medicalSpecialtyId_save = id;
  };

  const handleProvinceId = (id) => {
    setValue({
      ...values,
      area_id: null,
      location_id: null,
    });
    setProvinceId(id);
    setAreaId(null);
    setLocationId(null);

    provinceId_save = id;
  };

  const handleAreasId = (value) => {
    setValue({
      ...values,
      area_id: value,
      location_id: null,
    });
    setAreaId(value.value);

    areaId_save = value.value;
  };

  const handleLocationsId = (value) => {
    setValue({
      ...values,
      location_id: value,
    });
    setLocationId(value.value);

    locationId_save = value.value;
  };
  // COMMENT: START CODE  Dario
  const handleChangeName = (name) => {
    setMedicalNameEntity(name);
    medicalNameEntity_save = name;
  };

  const handleCleanFilters = () => {

    medicalServiceId_save="";
    medicalSpecialtyId_save="";
    locationId_save="";
    areaId_save="";
    provinceId_save="";
    medicalNameEntity_save="";
  };
  

  useEffect(() => {}, [pageLoadedOnce]);
  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Row className="section_top_responsive w-100" noGutters>
        {/* COMMENT: START CODE Dario*/}
        <Col xs={12} sm={4} className="px-1 my-1 my-sm-1">
          <MedicalNameSelect onSelect={handleChangeName} id="medicalName"/>
        </Col>
        <Col xs={12} sm={4} className="px-1 my-1 my-sm-1">
          <MSSelect onSelect={handleSetServiceId} />
        </Col>
        <Col xs={12} sm={4} className="px-1 my-1 my-sm-1">
          <MSpecialties onSelect={handleSpecialtyId} />
        </Col>
        <Col xs={12} sm={4} className="px-1 my-1 my-sm-1">
          <PSelect onSelect={handleProvinceId} />
        </Col>
        <Col xs={12} sm={4} className="px-1 my-1 my-sm-1">
          <ASelect onSelect={handleAreasId} value={values.area_id} />
        </Col>
        <Col xs={12} sm={4} className="px-1 my-1 my-sm-1 w-100">
          <LSelect onSelect={handleLocationsId} value={values.location_id} />
        </Col>
        
        
      </Row>

      <Row className="mb-5">
      
        <Col xs={12} sm={12} md={12} className="px-1 my-1 my-sm-1">
          <Link
            className="float-right ml-2 mr-5 btn-orange_outline"
            to="#"
            onClick={() => {
              var contentcopy = urlCartillamedica;
              
              if(medicalServiceId_save != "" || medicalSpecialtyId_save != "" || locationId_save != "" || areaId_save != "" || provinceId_save != "" || medicalNameEntity_save != ""){
                contentcopy +=
                "medicalServiceId_save=" + medicalServiceId_save +
                "&medicalSpecialtyId_save=" + medicalSpecialtyId_save +
                "&locationId_save=" + locationId_save +
                "&areaId_save=" + areaId_save +
                "&provinceId_save=" + provinceId_save +
                "&medicalNameEntity_save=" + medicalNameEntity_save;
              }

              navigator.clipboard.writeText(contentcopy);

              toast.success("Link copiado! ");
              handleCleanFilters();
              console.log(contentcopy);
            }}
          >
            ➩ Compartir
          </Link>
          
        </Col>
        
      </Row>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  filters: state.medicalEntity.filters,
  loading: state.medicalEntity.loading,
});

export default connect(mapStateToProps, {
  searchMedicalEntities,
  setMedicalServiceId,
  setMedicalSpecialtyId,
  setLocationId,
  setAreaId,
  setProvinceId,
  setMedicalNameEntity,
})(Searcher);
