import React from "react";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/tramites.svg";
import SolicitudInfoForm from "../SolicitudInfoForm";

const SolicitudInfo = () => {
  return (
    <>
      <PanelTitle icon={Icon}>Trámites / Solicitud de infomación</PanelTitle>
      <Row className="mx-0 mt-5">
        <Col xs={12} md={6} className="pb-4">
          <h6 className="mb-3">
            Por favor, indique a continuación que información desea recibir:
          </h6>
          <SolicitudInfoForm />
        </Col>
      </Row>
    </>
  );
};

export default SolicitudInfo;
