import React from "react";
import { Container, Row, Col } from "reactstrap";
import ServiceCard from "./ServiceCard";

import universalImage from "../../assets/img/universal_assistance.svg";

import CMImage from "../../assets/img/cartilla_medica_icon.svg";
import PlanesImage from "../../assets/img/planes_icono.svg";

const servicesUrl = [
  { title: "A-10 PLUS", url: "/nuestros-planes?a10" },  
  { title: "C-60 PLUS", url: "/nuestros-planes?c60" }
];

const Services = () => (
  <Container>
    <Row className="mx-0 mt-100 mb-100 row-eq-height">
      <Col sm="4" className="mb-4">
        <ServiceCard
          title="Cartilla Médica"
          text="Encontrá todos nuestros centros médicos y especialidades que necesites."
          image={CMImage}          
          wrapperLink
        />
      </Col> 
      <Col sm="4" className="mb-4">
        <ServiceCard
          title="Universal Assistance"
          text="Contá con la más completa cobertura de asistencia al viajero en Argentina."
          image={universalImage}
          white
          wrapperLinkUniversa

        />
      </Col>
      <Col sm="4" className="mb-4">
        <ServiceCard
          title="Planes de Salud"
          image={PlanesImage}
          servicesUrl={servicesUrl}
        />
      </Col>
    </Row>
  </Container>
);

export default Services;
