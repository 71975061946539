// COMMENT: CREATE THIS FILE
import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_MEDICAL_NAME_ENTITIES,
  GET_MEDICAL_NAME_ENTITIES_FAILURE,
  GET_MEDICAL_NAME_ENTITIES_SUCCESS,
} from "./types/medicalNameEntities";
import { LogOut } from "./auth";

export const getMedicalNameEntities = () => async (dispatch) => { 
  dispatch({ type: LOADING_MEDICAL_NAME_ENTITIES });

  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }


  try {
    /*Leer variable spor Get*/
    const p=getParameterByName("p");
    //alert(p);
    const baseUrl = `${API_URL}/api/web/medical_entity`;
    const pagination = `?orderBy=name&page=1&pagination=1000`;

    let URL = `${baseUrl}${pagination}`;
    const response = await axios.get(URL);


      dispatch({
        type: GET_MEDICAL_NAME_ENTITIES_SUCCESS,
        payload: {
          data: [
            {
              value: "all",
              label: "TODOS",
            },
            ...response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          ],
        },
      });
      

      var url = $(window.location).attr('origin');
      var path = $(window.location).attr('pathname');

     // alert (url+path);
      
      //document.getElementById('sel').value = 'bike';​​​​​​​​​​

    


    




    return response.status;
  } catch (error) {
    dispatch({ type: GET_MEDICAL_NAME_ENTITIES_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0011)");
    }
    return error.response.status;
  }
};

