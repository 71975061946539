import React from "react";
import ContentLoader from "react-content-loader";

const ListLoading = () => {
  return (
    <>
      <ContentLoader
        height={250}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        className="loaderContent"
        preserveAspectRatio="none"
      >
        <circle cx="10" cy="17" r="10" />
        <rect x="25" y="10" rx="5" ry="5" width="100%" height="15" />

        <circle cx="10" cy="57" r="10" />
        <rect x="25" y="50" rx="5" ry="5" width="100%" height="15" />

        <circle cx="10" cy="97" r="10" />
        <rect x="25" y="90" rx="5" ry="5" width="100%" height="15" />

        <circle cx="10" cy="137" r="10" />
        <rect x="25" y="130" rx="5" ry="5" width="100%" height="15" />

        <circle cx="10" cy="177" r="10" />
        <rect x="25" y="170" rx="5" ry="5" width="100%" height="15" />
      </ContentLoader>
    </>
  );
};

export default ListLoading;
