// COMMENT: Add TODOS
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  GET_SERVICES_LOADING,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE
} from "./types/medicalServices";

export const getMedicalServices = () => async dispatch => {
  dispatch({ type: GET_SERVICES_LOADING });

  try {
    const baseUrl = `${API_URL}/api/web/medical_service`;
    const pagination = `?pagination=20&orderBy=description`;

    let URL = `${baseUrl}${pagination}`;

    const response = await axios.get(URL);

    dispatch({
      type: GET_SERVICES_SUCCESS,
      payload: {
        data: [
          {
            value: "all",
            label: "TODOS",
          },
          ...response.data.data.map((item) => ({
            value: item.id,
            label: item.description,
          })),
        ],
      },
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_SERVICES_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0009)");
    }
    return error.response.status;
  }
};
