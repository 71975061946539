import React from "react";
import ContentLoader from "react-content-loader";

const PersonalDataLoading = () => {
  return (
    <ContentLoader
      height={120}
      speed={2}
      className="loaderContent"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="10" rx="5" ry="5" width="100%" height="10" />

      <rect x="0" y="30" rx="5" ry="5" width="100%" height="10" />

      <rect x="0" y="50" rx="5" ry="5" width="100%" height="10" />

      <rect x="0" y="70" rx="5" ry="5" width="100%" height="10" />

      <rect x="0" y="90" rx="5" ry="5" width="100%" height="10" />
    </ContentLoader>
  );
};

export default PersonalDataLoading;
