import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { connect } from "react-redux";
import { LogOut } from "../../redux/actions/auth";

import ImgMenu from "../../assets/img/menu-img.jpg";
import Hamburger from "../../assets/img/hamburguer.svg";
import LogoNaranja from "../../assets/img/logo-naranja.svg";
import UserMobile from "../../assets/img/user_mobile.svg";
import MyAccount from "../../assets/img/mi_cuenta.svg";
import LogOutIcon from "../../assets/img/cerrar_sesion.svg";
import Button from "../Button";

const MobileNavbar = ({ user, LogOut, history }) => {
  const [isOpen, setOpen] = useState(false);
  const [isCollapse, setCollapse] = useState(false);

  return (
    <header className="d-block d-lg-none">
      <Row className="mx-0 header__orange">
        <Col xs="12" className="p-0">
          <span>Urgencias las 24hs. 0810-999-2628</span> <span>|</span>{" "}
          <span>Admin. 4588-5900</span>
        </Col>
      </Row>

      <div className={`sidenav ${isOpen ? "mySidenav__config" : ""}`}>
        <div className="p-relative">
          <img src={ImgMenu} alt="menu" className="w-100 menu-mob__img" />
          <button className="closebtn" onClick={() => setOpen(!isOpen)}>
            &times;
          </button>
        </div>

        <Nav className="navbar__mobile" navbar>
          <NavItem className="w-100">
            <Link to="/nuestros-planes" onClick={() => setOpen(!isOpen)}>
              Planes
            </Link>
          </NavItem>
          <NavItem className="w-100">
            <Link to="/cartilla-medica" onClick={() => setOpen(!isOpen)}>
              Cartilla médica
            </Link>
          </NavItem>
          <NavItem className="w-100">
            <Link to="/farmacias" onClick={() => setOpen(!isOpen)}>
              Farmacias
            </Link>
          </NavItem>
          <NavItem className="w-100">
            <Link to="/centros-medicos" onClick={() => setOpen(!isOpen)}>
              Centros médicos
            </Link>
          </NavItem>

<NavItem className="w-100">
            <button
              className="nav-link nav-link__as-mob"
              onClick={() => setCollapse(!isCollapse)}
            >
              Servicios adicionales{" "}
              <i
                className={`fa fa-caret-${isCollapse ? "up" : "down"}`}
                aria-hidden="true"
              />
            </button>
            <Collapse isOpen={isCollapse} navbar>
              <Nav navbar className="flex-column pl-2">
                <NavItem>
                  <Link
                    to="/universal-assistance"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Universal Assistance
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/plan-vacunar"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Plan Vacunar
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/servicios-estetica-bienestar"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Estética y Bienestar
                  </Link>
                </NavItem>
                
                
              </Nav>
            </Collapse>
          </NavItem>


          <NavItem className="w-100">
            <button
              className="nav-link nav-link__as-mob"
              onClick={() => setCollapse(!isCollapse)}
            >
              Institucional{" "}
              <i
                className={`fa fa-caret-${isCollapse ? "up" : "down"}`}
                aria-hidden="true"
              />
            </button>
            <Collapse isOpen={isCollapse} navbar>
              <Nav navbar className="flex-column pl-2">
                <NavItem>
                  <Link
                    to="/quienes-somos"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Quiénes Somos
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/reglamento"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Reglamento
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/preguntas-frecuentes"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Preguntas Frecuentes
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/contacto"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Contacto
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/trabaja-con-nosotros"
                    className="nav-link__as-mob_item"
                    onClick={() => {
                      setOpen(!isOpen);
                      setCollapse(!isCollapse);
                    }}
                  >
                    Trabajá con Nosotros
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </NavItem>
        </Nav>
      </div>

      <Row className="mx-0 justify-content-between align-items-center header__mobile">
        <span onClick={() => setOpen(!isOpen)}>
          <img
            src={Hamburger}
            alt="hamburguer menu"
            className="menu-mob_icon-menu"
          />
        </span>
        <Link to="/">
          <img
            src={LogoNaranja}
            alt="mobile logo"
            className="header__logo-as__mobile"
          />
        </Link>
        {!user ? (
          <Button url="/login" type="orange-outline">
            Ingrese
          </Button>
        ) : (
          <Nav className="navbar__desktop no-hover-line" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <img
                  src={UserMobile}
                  alt="user logo"
                  className="menu-mob_icon-menu__user  mx-3"
                />
              </DropdownToggle>
              <DropdownMenu tag="ul" className="dropdown-menu__as" right>
                <li>
                  <DropdownItem
                    className="btn-menu"
                    onClick={() => history.push("/panel")}
                  >
                    <img src={MyAccount} alt="my account icon" /> Mi cuenta
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem className="btn-menu" onClick={() => LogOut()}>
                    <img src={LogOutIcon} alt="logout icon" /> Cerrar sesión
                  </DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        )}
      </Row>
    </header>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      LogOut
    }
  )(MobileNavbar)
);
