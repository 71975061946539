import React from "react";
import Banner from "../components/Banner";
import Tags from "../components/Meta/Tags";
import Content from "../components/Pharmacies/Content";

import ImageBanner from "../assets/img/farmacias.jpg";

const Farmacias = () => (
  <>
  <Tags 
    title="Farmacias" 
    description="Farmacias en todo caba. Asistencia inmediata." 
    keywords="Farmacias en caba, Farmacia cosmopolita, Medicina, Medicamentos, Descuentos para jubilados, Farmacia de turno" 
    canonical="/farmacias" />
    <Banner
      title="FARMACIAS"
      image={ImageBanner}
      subtitle="Conocé nuestros planes y elegí el más adecuado para vos."
      size="6"
    />
    <Content />
  </>
);

export default Farmacias;
