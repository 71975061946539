import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Residencias/Content";

import ImageBanner from "../assets/img/residencia_medica.jpg";

const ResidenciaMedica = () => (
  <>
    <Tags 
      title="Residencia médica" 
      description="Docencia e investigación" 
      keywords="medicos, especialidad, clinica medica, cardiologia, pacientes, programa, aprendizaje , docencia, jefe de residentes" 
      canonical="/residencia-medica" />
    <Banner
      title="RESIDENCIA MÉDICA"
      image={ImageBanner}
      size="6"
      height="rm"
    />
    <Content />
  </>
);

export default ResidenciaMedica;
