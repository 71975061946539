import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOCATIONS_LOADING,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE
} from "./types/locations";

export const getLocationByAreaId = id => async dispatch => {
  dispatch({ type: LOCATIONS_LOADING });

  try {
    const baseUrl = `${API_URL}/api/web/locations`;
    const pagination = `?pagination=300&area_id=${id}`;

    let URL = `${baseUrl}${pagination}`;

    const response = await axios.get(URL);

    dispatch({
      type: GET_LOCATIONS_SUCCESS,
      payload: {
        data: response.data.data.map(item => ({
          value: item.id,
          label: item.name
        }))
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_LOCATIONS_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0009)");
    }
    return error.response.status;
  }
};
