import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import PlanA10 from "./PlanA10";
import PlanC60 from "./PlanC60";

const Content = ({ location }) => {
  const [tab, setTab] = useState("1");

  useState(() => {
    if (location.search.length > 0) {
      const searchParams = location.search.split("?")[1];

      switch (searchParams) {
        case "c60": {
          setTab("2");
          return;
        }

        default: {
          setTab("1");
          return;
        }
      }
    }
  }, [location]);

  return (
    <Row className="mx-0">
      <Col xs="12" className="p-0">
        <Nav
          tabs
          className="row mx-0 nav nav-tabs justify-content-center text-center"
        >
          <NavItem className="col-6 col-md-4">
            <NavLink
              className={`link-tab-planes link-tab-planes ${
                tab === "1" ? "active" : ""
              }`}
              onClick={() => setTab("1")}
            >
              A-10 PLUS
            </NavLink>
          </NavItem>
          <NavItem className="col-6 col-md-4">
            <NavLink
              className={`link-tab-planes link-tab-planes ${
                tab === "2" ? "active" : ""
              }`}
              onClick={() => setTab("2")}
            >
              C-60 PLUS
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="1">
            <PlanA10 />
          </TabPane>

          <TabPane tabId="2">
            <PlanC60 />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default withRouter(Content);
