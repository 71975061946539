import React from "react";

import { Row, Col } from "reactstrap";

import Sidebar from "./Sidebar";

const PanelLayout = ({ children }) => {
  return (
    <main className="wrapper section__ppal">
      <Sidebar />
      <div id="content" className="dashboard-bk">
        <Row className="mx-0">
          <Col xs="12" className="py-3">
            <div className="box-containers-dashboard">{children}</div>
          </Col>
        </Row>
      </div>
    </main>
  );
};

export default PanelLayout;
