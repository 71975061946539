import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, ReactReduxContext, useSelector } from "react-redux";
import {
  resetReducer,
  setPage,
  searchMedicalEntities,
} from "../../redux/actions/medicalEntity";
import { resetAllMaps } from "../../redux/actions/common";
import Container from "./Container";
import PageWrapper from "../PageWrapper";
import Searcher from "./Searcher";
import Maps from "../Maps";
import Loader from "../ListLoading";
import Paginator from "../Paginator";

const Content = ({
  markers,
  resetReducer,
  loading,
  meta,
  links,
  setPage,
  searchMedicalEntities,
  filters,
  resetAllMaps,
}) => {
  const [fetchData, setFetchData] = useState(false);

  const { useContext } = React;
  const { store } = useContext(ReactReduxContext);

  const usuario_data = useSelector((state) => state.auth.user);

  const defaultPosition = {
    center: {
      lat: -34.6036844,
      lng: -58.381559100000004,
    },
    zoom: 11,
  };

  const handlePaginator = async (page) => {
    setPage(page);
    setFetchData(true);
  };

  useEffect(() => {
    filters.user_data = usuario_data;
    searchMedicalEntities(filters);
  }, [fetchData, searchMedicalEntities, filters]);

  useEffect(() => {
    return () => {
      resetAllMaps();
      resetReducer();
    };
  }, [resetReducer, resetAllMaps]);

  return (
    <PageWrapper>
      <Searcher />
      <Row className="w-100">
        <Col xs="12" sm="6">
          <span className="d-block mb-4">
            Resultado de búsqueda: <span>busqueda...</span>{" "}
          </span>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <Container />
            </>
          )}
          {markers.length > 0 && (
            <Paginator
              handlePaginator={handlePaginator}
              loading={loading}
              currentPage={meta.currentPage}
              first={links.first}
              last={links.last}
              next={links.next}
              prev={links.prev}
              center={true}
            />
          )}
        </Col>
        <Col xs="12" sm="6">
          <Maps
            center={defaultPosition.center}
            zoom={defaultPosition.zoom}
            markers={markers}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  markers: state.medicalEntity.markers,
  loading: state.medicalEntity.loading,
  links: state.medicalEntity.links,
  meta: state.medicalEntity.meta,
  filters: state.medicalEntity.filters,
});

export default connect(mapStateToProps, {
  resetReducer,
  setPage,
  searchMedicalEntities,
  resetAllMaps,
})(Content);
