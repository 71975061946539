import React from "react";

const PlansBenefits = ({ children, iconUrl }) => {
  return (
    <h6 className="planes__beneficios d-flex align-items-center">
      {" "}
      <img
        className="planes__icon-check d-block"
        src={iconUrl}
        alt="benefits icon"
      />{" "}
      {children}
    </h6>
  );
};

export default PlansBenefits;
