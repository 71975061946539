// COMMENT: CREATE THIS FILE
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { getMedicalNameEntities } from "../redux/actions/medicalNameEntities";
import { customStyles } from "./styles/common";

const MedicalNameSelect = ({
  getMedicalNameEntities,
  data,
  loading,
  onSelect,
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getMedicalNameEntities();
    };

    fetchData();
  }, [getMedicalNameEntities]);

  //guardo el valor del filtro ,start 
  // useEffect(() => {
  //   // Recupero el valor guardado de la sesion con sessionStorage
  //   const savedValue = localStorage.getItem('medicalNameEntity_save');
  //   if (savedValue) {
  //     setValue(JSON.parse(savedValue));
  //   }
  // }, []); // Run only once on component mount

  // const handleChange = (value) => {
  //   setValue(value);
  //   onSelect(value.label);
  //   localStorage.setItem('medicalNameEntity_save', JSON.stringify(value));
  // };
  // //////end

  return (
    <Select
      className="basic-single"
      classNamePrefix="custom-select"
      options={data}
      value={value}
      onChange={(value) => {
        setValue(value);
        onSelect(value.label);
      }}
      // onChange={handleChange}
      placeholder="Nombre del Prestador"
      styles={customStyles}
      noOptionsMessage={() => "No se encontraron datos."}
      isLoading={loading}
      clearable={false}
    />
  );
};

const mapStateToProps = (state) => ({
  data: state.medicalNameEntities.data,
  loading: state.medicalNameEntities.loading,
});

export default connect(mapStateToProps, {
  getMedicalNameEntities,
})(MedicalNameSelect);
