import React from "react";
import { Input } from "reactstrap";

const EditableInput = ({ name, value, onChange }) => {
  return (
    <div className="editable-wrapper">
      <Input
        type="text"
        name={name}
        onChange={e => {
          onChange({ name, value: e.target.value });
        }}
        value={value}
        className="form-control__round"
      />
    </div>
  );
};

export default EditableInput;
