import React from "react";
import { Container, Row } from "reactstrap";
import PortalItem from "./PortalItem";

import Image1 from "../../assets/img/portal-gestion-online.jpg";
import Image2 from "../../assets/img/turnos-online.jpg";

const Portals = () => (
  <Container>
    <Row className="mx-0 mt-100 mb-100">
      <PortalItem
        title="Portal de gestión online, dónde estes."
        text="Administrá tu cuenta online y accedé a la información que necesites en cualquier momento, desde cualquier lugar."
        url="/login"
        image={Image1}
      />
      <PortalItem
        title="Turnos Online, rápido y sencillo."
        text="Hace click a continuación para solicitar tu turno, es más ágil y más fácil."
        url="https://turnos.elijasuturno.com.ar/turnos_php2/?es=alta"
        image={Image2}
        external={true}
        onClick={() => {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-622659677/a1_SCOXcmoUZEN2Q9KgC',
            'value': 1.0,
            'currency': 'ARS'
          });
        }}
      />

    </Row>
  </Container>
);

export default Portals;
