import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PlansBenefits from "../PlansBenefits";

import ArrowRightPlans from "../../assets/img/arrow-derecha-planes.svg";
import ArrowRightWhite from "../../assets/img/arrow-blanca.svg";
import PlanDoc from "../../assets/files/pdfs/plan10.pdf";





import Planet from "../../assets/img/planet-earth.svg";
import FirstAid from "../../assets/img/first-aid-kit.svg";
import Patient from "../../assets/img/patient.svg";
import Hospital from "../../assets/img/hospital.svg";
import Pharmacy from "../../assets/img/pharmacy.svg";
import Clipboard from "../../assets/img/clipboard.svg";
import Rehab from "../../assets/img/rehabilitation.svg";

//Agrego Dario 
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173013637-1');

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};
//




const PlanA10 = () => (
  <Container>
    <Row className="mx-0 my-70 justify-content-center">
      <Col xs="12" sm="5">
        <h2 className="mb-4">
          PLAN
          <br />
          A-10 PLUS
        </h2>
        <p className="mb-4 planes__text">
          A través de la más amplia red de prestadores, vos y tu familia
          accederán a un sistema de protección médico asistencial privado de
          excelencia.
        </p>
        <Link to="/contacto" className="mb-3 btn-big d-block btn-orange" onClick={()=> 
	Event("Asociarme", "Redireccion", "label")
} >
          Quiero asociarme <img src={ArrowRightWhite} alt="arrow icon" />
        </Link>
        <a
          href={PlanDoc}
          className="mb-3 btn-big d-block btn-blue-outline"
          download
        >
          Ver plan completo <img src={ArrowRightPlans} alt="arrow icon" />
        </a>
       

      </Col>
      <Col xs="12" sm={{ size: "4", offset: "1" }}>
        <PlansBenefits iconUrl={Planet}>Universal Assistance</PlansBenefits>
        <PlansBenefits iconUrl={FirstAid}>Consultas médicas</PlansBenefits>
        <PlansBenefits iconUrl={Patient}>Internaciones</PlansBenefits>
        <PlansBenefits iconUrl={Hospital}>
          Emergencias domiciliarias
        </PlansBenefits>
        <PlansBenefits iconUrl={Pharmacy}>Farmacias</PlansBenefits>
        <PlansBenefits iconUrl={Clipboard}>Análisis</PlansBenefits>
        <PlansBenefits iconUrl={Rehab}>Kinesiología</PlansBenefits>
      </Col>
    </Row>
  </Container>
);

export default PlanA10;
