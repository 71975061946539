import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store, persistor } from "./redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { setTokenHeaders } from "./redux/api";

import * as serviceWorker from "./serviceWorker";

import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/scss/font-awesome.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "./assets/styles/styles.scss";
import "moment/locale/es";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./lib/virtual-select.min.css";

moment().locale("es");

const render = () => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

store.subscribe(render);
render();

if (sessionStorage.jwtToken) {
  setTokenHeaders(sessionStorage.jwtToken);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
