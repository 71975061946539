import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  AREAS_LOADING,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE
} from "./types/areas";

export const getAreasByProvinceId = id => async dispatch => {
  dispatch({ type: AREAS_LOADING });

  try {
    const baseUrl = `${API_URL}/api/web/areas`;
    const pagination = `?pagination=200&orderBy=name&province_id=${id}`;

    let URL = `${baseUrl}${pagination}`;

    const response = await axios.get(URL);

    dispatch({
      type: GET_AREAS_SUCCESS,
      payload: {
        data: response.data.data.map(item => ({
          value: item.id,
          label: item.name
        }))
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_AREAS_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error al cargar ciudad o provincias (Error 0003).");
    }
    return error.response.status;
  }
};
