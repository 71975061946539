import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import LogoBlanco from "../../assets/img/logo-blanco.svg";
import ContactLink from "../ContactLink";
import ArchivoPrivacidad from "../../assets/files/23-02-21_politicas_de_privacidad_sitio_web.docx";
import WhatsAppFloatingButton from "./WhatsAppFloatingButton"


const FooterDesktop = () => (


  <Row className="d-none d-lg-flex mx-0 footer__container">
    <Col sm="2" className="footer__logo-line">
      <LazyLoadImage
        src={LogoBlanco}
        alt="footer logo"
        className="w-100 p-4 pr-5"
        effect="blur"
      />

      <Row className="mx-0">
        <Col xs="12"> &nbsp; </Col>
        <Col xs="12"> &nbsp; </Col>


        <Col xs="2">
          <a
            href="https://www.facebook.com/pg/altasaludoficial/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50, /* Agranda el botón a 50px de ancho */
              height: 50, /* Agranda el botón a 50px de alto */
              background: "#fff", /* Cambia el color de fondo a blanco */
              borderRadius: "50%", /* Hace el botón circular */
              border: "none", /* Elimina el borde del botón */
              cursor: "pointer", /* Indica que es un elemento interactivo */
            }}
          >
            <FaFacebook size={35} color="#2e3742" />

          </a>

        </Col>
        {/* <Col xs="10" style={{ fontSize: "13px" }}>
          Facebook
        </Col> */}

        {/* <Col xs="10" style={{ fontSize: "13px" }}>
          <Link to={{ pathname: "https://www.facebook.com/pg/altasaludoficial" }} target='_blank' className="footer-as__links_desktop">
            altasaludoficial
          </Link>

        </Col> */}
        <Col xs="12"> &nbsp; </Col>

        <Col xs="2">
          <a
            href="https://www.instagram.com/altasaludoficial/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50, /* Agranda el botón a 50px de ancho */
              height: 50, /* Agranda el botón a 50px de alto */
              background: "#fff", /* Cambia el color de fondo a blanco */
              borderRadius: "50%", /* Hace el botón circular */
              border: "none", /* Elimina el borde del botón */
              cursor: "pointer", /* Indica que es un elemento interactivo */
            }}
          >
            <FaInstagram size={35} color="#2e3742" />
          </a>

        </Col>


        {/* <Col xs="10" style={{ fontSize: "13px" }}>
          <a
            href="https://www.instagram.com/altasaludoficial/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <FaInstagram size={24} color="#000" />
          </a>
        </Col> */}

        <Col xs="12"> &nbsp; </Col>

        <Col xs="2">
          <a
            href="https://web.whatsapp.com/send?phone=5491161653681"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50, /* Agranda el botón a 50px de ancho */
              height: 50, /* Agranda el botón a 50px de alto */
              background: "#fff", /* Cambia el color de fondo a blanco */
              borderRadius: "50%", /* Hace el botón circular */
              border: "none", /* Elimina el borde del botón */
              cursor: "pointer", /* Indica que es un elemento interactivo */
            }}
          >
            <FaWhatsapp size={35} color="#2e3742" />
          </a>

        </Col>
        {/* <Col xs="10" style={{ fontSize: "13px" }}>
          WhatsApp
        </Col> */}

      </Row>

    </Col>
    <Col sm={{ size: "9", offset: 1 }}>
      <Row className="mx-0">
        <Col xs="3">
          <h6 className="mb-3 footer__titles-desktop">Institucional</h6>
          <ul>
            <li>
              <Link to="/quienes-somos" className="footer-as__links_desktop">
                Quiénes somos
              </Link>
            </li>
            <li>
              <Link to="/reglamento" className="footer-as__links_desktop">
                Reglamento
              </Link>
            </li>
            <li>
              <Link
                to="/preguntas-frecuentes"
                className="footer-as__links_desktop"
              >
                Preguntas frecuentes
              </Link>
            </li>
            <li>
              <Link to="/contacto" className="footer-as__links_desktop">
                Contacto
              </Link>
            </li>
            <li>
              <Link
                to="/trabaja-con-nosotros"
                className="footer-as__links_desktop"
              >
                Trabajá con Nosotros
              </Link>
            </li>

          </ul>
        </Col>
        <Col xs="3">
          <h6 className="mb-3 footer__titles-desktop">Servicios adicionales</h6>
          <ul>
            <li>
              <Link
                to="/universal-assistance"
                className="footer-as__links_desktop"
              >
                Universal Assistance
              </Link>
            </li>
            <li>
              <Link to="/plan-vacunar" className="footer-as__links_desktop">
                Plan Vacunar
              </Link>
            </li>
            <li>
              <Link
                to="/servicios-estetica-bienestar"
                className="footer-as__links_desktop"
              >
                Servicios de estética y bienestar
              </Link>
            </li>

          </ul>
        </Col>
        <Col xs="3">
          <h6 className="mb-3 footer__titles-desktop">Actividad académica</h6>
          <ul>
            <li>
              <Link
                to="/residencia-medica"
                className="footer-as__links_desktop"
              >
                Residencia Médica
              </Link>
            </li>
            <li>
              <Link
                to="/escuela-enfermeria"
                className="footer-as__links_desktop"
              >
                Escuela de Enfermería
              </Link>
            </li>

          </ul>
        </Col>
        <Col xs="3">
          <h6 className="mb-3 footer__titles-desktop">Contacto</h6>
          <Link to="/contacto" className="d-block footer-as__links_desktop">
            Envianos tu consulta
          </Link>
          <span className="d-block footer-as__links_desktop">
            Tel: 4588-5900
          </span>
          <span className="d-block footer-as__links_desktop">
            Av. Nazca 1169, CABA
          </span>
          <span className="d-block footer-as__links_desktop">
            info@altasalud.com.ar
          </span>
        </Col>

      </Row>

      <Row className="mx-0">
        <Col xs="3">
          <ul>
            <li>
              <ContactLink url="/contacto" >
                Dejar de ser Socio
              </ContactLink>
            </li>
          </ul>
        </Col>

        <Col xs="3">
          <ul>
            <li>
              <ContactLink url="/contacto" >
                Solicitud de arrepentimiento
              </ContactLink>
            </li>
          </ul>
        </Col>

        <Col xs="3">
          <ul>
            <li>
              <a className="footer__titles-desktop" href="./files/23-02-21_politicas_de_privacidad_sitio_web.pdf">
                <h6>Política de Privacidad</h6>
              </a>
            </li>
          </ul>

        </Col>

        <Col xs="3">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13133.554712517887!2d-58.4745523!3d-34.6196169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x57e30dd09bf6be04!2sAlta+Salud!5e0!3m2!1ses!2sar!4v1560866647180!5m2!1ses!2sar"
            width="100%"
            frameBorder="0"
            style={{ border: "0", marginTop: "10px" }}
            allowFullScreen
            title="google map"
          />
        </Col>
      </Row>

    </Col>
    <Col sm="12" align="center"><br />
      <span style={{ fontSize: "10px" }}>Superintendencia de Servicio de Salud / Organo de control de Obras Sociales y entidades de Medicina Prepagas / 0800-222-SALUD (72583) www.sssalud.gob.ar RNEMP Nº1-1062-6 </span>
    </Col>
    <Col sm="12" align="center">
      <span style={{ fontSize: "10px" }}>Contrato de adhesión-Ley N° 24.240 Defensa del cosumidor</span>
    </Col>

    <Col>
      <WhatsAppFloatingButton phoneNumber="5491161653681" />

    </Col>
  </Row>

);

export default FooterDesktop;
