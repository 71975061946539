import React from "react";
import { Container, Row } from "reactstrap";

const PageWrapper = ({ children, fluid, left }) => {
  return (
    <Container fluid={fluid}>
      <Row
        className={`mx-0 ${
          left ? "justify-content-start" : "justify-content-center"
        } my-70 section_top_responsive mb-5`}
      >
        {children}
      </Row>
    </Container>
  );
};

export default PageWrapper;
