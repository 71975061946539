import React, { useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";

import { Row, Col } from "reactstrap";
import { FaWhatsapp } from 'react-icons/fa';

import ArrowFooter from "../../assets/img/footer-arrow.svg";
import ContactLink from "../ContactLink";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

import WhatsAppFloatingButton from "./WhatsAppFloatingButton"


const FooterMobile = ({ location }) => {
  const accordion = useRef();

  useEffect(() => {
    Array.from(accordion.current.children).forEach(item => {
      const button = item.children[0].children[0];
      if (Array.from(item.children[1].classList).includes("show")) {
        button.click();
      }
    });
  }, [location.pathname]);

  

  return (
    <Row className="d-block d-lg-none mx-0 footer__container" noGutters>
      <Col xs="12">
        <Accordion ref={accordion}>
          <Card className="card__footer">
            <Card.Header className="p-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="footer-mov__title w-100 text-center"
              >
                <h6 className="m-0">
                  Institucional <img src={ArrowFooter} alt="arrow" />
                </h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ul className="text-center">
                  <li>
                    <Link
                      to="/quienes-somos"
                      className="footer-as__links_desktop"
                    >
                      Quiénes somos
                    </Link>
                  </li>
                  <li>
                    <Link to="/reglamento" className="footer-as__links_desktop">
                      Reglamento
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/preguntas-frecuentes"
                      className="footer-as__links_desktop"
                    >
                      Preguntas frecuentes
                    </Link>
                  </li>
                  <li>
                    <Link to="/contacto" className="footer-as__links_desktop">
                      Contacto
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/trabaja-con-nosotros"
                      className="footer-as__links_desktop"
                    >
                      Trabajá con Nosotros
                    </Link>
                  </li>

                  <li>

                    <ContactLink url="/contacto" >
                      Dejar de ser Socio
                    </ContactLink>
                  </li>

                </ul>

              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="card__footer">
            <Card.Header className="p-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="footer-mov__title w-100 text-center"
              >
                <h6 className="m-0">
                  Servicios adicionales <img src={ArrowFooter} alt="arrow" />
                </h6>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <ul className="text-center">
                  <li>
                    <Link
                      to="/universal-assistance"
                      className="footer-as__links_desktop"
                    >
                      Universal Assistance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/plan-vacunar"
                      className="footer-as__links_desktop"
                    >
                      Plan Vacunar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/servicios-estetica-bienestar"
                      className="footer-as__links_desktop"
                    >
                      Servicios de estética y bienestar
                    </Link>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="card__footer">
            <Card.Header className="p-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="2"
                className="footer-mov__title w-100 text-center"
              >
                <h6 className="m-0">
                  Actividad académica <img src={ArrowFooter} alt="arrow" />
                </h6>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <ul className="text-center">
                  <li>
                    <Link
                      to="/residencia-medica"
                      className="footer-as__links_desktop"
                    >
                      Residencia Médica
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/escuela-enfermeria"
                      className="footer-as__links_desktop"
                    >
                      Escuela de Enfermería
                    </Link>
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="card__footer">
            <Card.Header className="p-0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="3"
                className="footer-mov__title w-100 text-center"
              >
                <h6 className="m-0">
                  Contacto <img src={ArrowFooter} alt="arrow" />
                </h6>
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey="3">
              <Card.Body className="text-center">
                <Link
                  to="/contacto"
                  className="d-block footer-as__links_desktop"
                >
                  Envianos tu consulta
                </Link>

                <span className="d-block footer-as__links_desktop">
                  Tel:4588-5900
                </span>
                <span className="d-block footer-as__links_desktop">
                  Av. Nazca 1169, CABA
                </span>

                <span className="d-block footer-as__links_desktop">
                  info@altasalud.com.ar
                </span>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13133.554712517887!2d-58.4745523!3d-34.6196169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x57e30dd09bf6be04!2sAlta+Salud!5e0!3m2!1ses!2sar!4v1560866647180!5m2!1ses!2sar"
                  width="100%"
                  frameBorder="0"
                  style={{ border: "0", marginTop: "10px" }}
                  allowFullScreen
                  title="google map"
                />
              </Card.Body>
            </Accordion.Collapse>

            <Card className="card__footer">
              <Card.Header className="p-0">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  className="footer-mov__title w-100 text-center"
                >

                  <Link to="/contacto">
                    <h6 className="m-0">Dejar de ser Socio</h6>
                  </Link>

                </Accordion.Toggle>
              </Card.Header>
            </Card>

            <Card className="card__footer">
              <Card.Header className="p-0">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  className="footer-mov__title w-100 text-center"
                >
                  <Link to="/contacto">
                    <h6 className="m-0">Solicitud de arrepentimiento</h6>
                  </Link>
                </Accordion.Toggle>
              </Card.Header>
            </Card>

            <Card className="card__footer">
              <Card.Header className="p-0">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  className="footer-mov__title w-100 text-center"
                >

                  <a href="./files/23-02-21_politicas_de_privacidad_sitio_web.pdf">
                    <h6 className="m-0">Política de Privacidad</h6>
                  </a>
                </Accordion.Toggle>
              </Card.Header>
            </Card>

          </Card>
        </Accordion>
      </Col>
      <Col xs="12">&nbsp;</Col>
      <Col xs="12">
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px"
        }}>

          <WhatsAppFloatingButton phoneNumber="5491161653681" href="whatsapp://send" />
          
          <a
            href="https://www.facebook.com/pg/altasaludoficial/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50, /* Agranda el botón a 50px de ancho */
              height: 50, /* Agranda el botón a 50px de alto */
              background: "#fff", /* Cambia el color de fondo a blanco */
              borderRadius: "50%", /* Hace el botón circular */
              border: "none", /* Elimina el borde del botón */
              cursor: "pointer", /* Indica que es un elemento interactivo */
            }}
          >
            <FaFacebook size={35} color="#2e3742" />

          </a>


          {/* <Col xs="10" style={{ fontSize: "13px" }}>
          Facebook
        </Col> */}

          {/* <Col xs="10" style={{ fontSize: "13px" }}>
          <Link to={{ pathname: "https://www.facebook.com/pg/altasaludoficial" }} target='_blank' className="footer-as__links_desktop">
            altasaludoficial
          </Link>

        </Col> */}



          <a
            href="https://www.instagram.com/altasaludoficial/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50, /* Agranda el botón a 50px de ancho */
              height: 50, /* Agranda el botón a 50px de alto */
              background: "#fff", /* Cambia el color de fondo a blanco */
              borderRadius: "50%", /* Hace el botón circular */
              border: "none", /* Elimina el borde del botón */
              cursor: "pointer", /* Indica que es un elemento interactivo */
            }}
          >
            <FaInstagram size={35} color="#2e3742" />
          </a>




          {/* <Col xs="10" style={{ fontSize: "13px" }}>
          <a
            href="https://www.instagram.com/altasaludoficial/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <FaInstagram size={24} color="#000" />
          </a>
        </Col> */}


          <a
            href="whatsapp://send?phone=5491161653681&app_absent=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 50, /* Agranda el botón a 50px de ancho */
              height: 50, /* Agranda el botón a 50px de alto */
              background: "#fff", /* Cambia el color de fondo a blanco */
              borderRadius: "50%", /* Hace el botón circular */
              border: "none", /* Elimina el borde del botón */
              cursor: "pointer", /* Indica que es un elemento interactivo */
            }}
          >
            <FaWhatsapp size={35} color="#2e3742" />
          </a>



        </div>

      </Col>

      <Col sm="12" align="center"><br /><br />
        <span style={{ fontSize: "10px" }}>Superintendencia de Servicio de Salud / Organo de control de Obras Sociales y entidades de Medicina Prepagas / 0800-222-SALUD (72583) www.sssalud.gob.ar RNEMP Nº1-1062-6 </span>
      </Col>
      <Col sm="12" align="center">
        <span style={{ fontSize: "10px" }}>Contrato de adhesión-Ley N° 24.240 Defensa del cosumidor</span>
      </Col>



    </Row>
  );
};

export default withRouter(FooterMobile);
