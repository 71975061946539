import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { FormGroup, FormFeedback, Input } from "reactstrap";
import Select from "react-select";

import { connect } from "react-redux";
import { getFormTypes } from "../../redux/actions/form_types";
import { getFormTypeByWord } from "../../lib/getFormTypeBySlug";
import { addContactForm } from "../../redux/actions/common";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubmitBtn from "../SubmitBtn";
import AddFile from "../../assets/img/agregar_archivo.svg";

import Trash from "../../assets/img/trash.png";

import { customStyles } from "../styles/common";

import FormLoading from "../FormLoading";

const puestos = [
  { label: "Administrativa", value: "Administrativa" },
  { label: "Enfermería", value: "Enfermería" },
  { label: "Maestranza", value: "Maestranza" },
  { label: "Cocina", value: "Cocina" },
  { label: "Contaduría", value: "Contaduría" },
  { label: "Proveedores", value: "Proveedores" },
  { label: "Prestador médico", value: "Prestador médico" },
  { label: "Otros", value: "Otros" }
];

const ContactForm = ({
  formTypes,
  loading,
  getFormTypes,
  location,
  addContactForm
}) => {
  const [formTypeId, setFormTypeId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileName02, setFileName02] = useState(null);
  const [fileName03, setFileName03] = useState(null);
  const [fileName04, setFileName04] = useState(null);
  const [isVisibleFrmGrp02, setDisplayFrmGrp02] = useState(false);
  const [isVisibleFrmGrp03, setDisplayFrmGrp03] = useState(false);
  const [isVisibleFrmGrp04, setDisplayFrmGrp04] = useState(false);

  useEffect(() => {
    const fetchFormTypes = async () => await getFormTypes();

    fetchFormTypes();
  }, [getFormTypes]);

  useEffect(() => {
    if (formTypes.length > 0) {
      setFormTypeId(getFormTypeByWord("trabaja", formTypes));
    }
  }, [location.pathname, formTypes]);

  if (loading) {
    return <FormLoading />;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        title: "Trabaja con nosotros",
        message: "",
        email: "",
        phone: "",
        attachment: "",
        attachment02: "",
        attachment03: "",
        attachment04: "",
        contact_form_type_id: formTypeId || ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Requerido."),
        email: Yup.string()
          .email()
          .typeError("Debe ser un email valido.")
          .required("Requerido."),
        message: Yup.string().required("Requerido.")
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const data = new FormData();

        data.append("attachment", values.attachment);
        data.append("attachment02", values.attachment02);
        data.append("attachment03", values.attachment03);
        data.append("attachment04", values.attachment04);

        const parsedMessage = `
          Puesto seleccionado: ${values.message.value}
        `;

        data.append("name", values.name);
        data.append("title", values.title);
        data.append("message", parsedMessage);
        data.append("email", values.email);
        data.append("phone", values.phone);
        data.append("contact_form_type_id", values.contact_form_type_id);

        const response = await addContactForm(data);

        setSubmitting(false);

        if (response === 200) {
          resetForm({
            name: "",
            message: "",
            email: "",
            phone: "",
            attachment: "",
            attachment02: "",
            attachment03: "",
            attachment04: "",
          });

          setFileName(null);
          setFileName02(null);
          setFileName03(null);
          setFileName04(null);
        }
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        handleBlur,
        values,
        handleChange,
        setFieldValue
      }) => (
        <Form className="mt-4">
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <FormGroup>
              <Input
                type="text"
                name="name"
                className={`form-control__round ${errors.name &&
                  touched.name &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue("name", e.target.value);
                }}
                value={values.name}
                placeholder="Nombre y Apellido"
              />
              {errors.name && touched.name && (
                <FormFeedback className="d-block">{errors.name}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Input
                type="text"
                name="email"
                className={`form-control__round ${errors.email &&
                  touched.email &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                placeholder="Email"
              />
              {errors.email && touched.email && (
                <FormFeedback className="d-block">{errors.email}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Input
                type="text"
                name="phone"
                className={`form-control__round ${errors.phone &&
                  touched.phone &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                placeholder="Teléfono"
              />
            </FormGroup>

            <FormGroup>
              <Select
                className={`${
                  errors.message && touched.message ? "select-error" : ""
                }`}
                classNamePrefix="custom-select"
                placeholder="Selecciona un puesto"
                styles={customStyles}
                options={puestos}
                value={values.message}
                onChange={value => {
                  setFieldValue("message", value);
                }}
              />
              {errors.message && touched.message && (
                <FormFeedback className="d-block">
                  {errors.message}
                </FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <img src={AddFile} className="pr-1" alt="add icon" />
              <label htmlFor="upload-photo" className="add-file-input">
                Añadir CV
              </label>
              <input
                className="d-block w-25"
                type="file"
                name="attachment"
                id="upload-photo"
                // multiple
                onChange={e => {
                  e.preventDefault();
                  let file = e.target.files[0];

                  if (!file) {
                    setDisplayFrmGrp02(false);
                    return;
                  }

                  setDisplayFrmGrp02(true);
                  setFieldValue("attachment", file);
                  setFileName(file.name);
                }}
              />
              {fileName && <small className="d-block">{fileName} - <a href="javascript:;"   onClick={e => {
                setFieldValue("attachment", null);
                   setFileName(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}

              <small className="d-block">
                (Extensiones permitidas: .doc, .docx y .pdf).
              </small>
            </FormGroup>

            <FormGroup className={`frmGrp02 ${isVisibleFrmGrp02 ? "" : "hidden"}`}>
              <img src={AddFile} className="pr-1" alt="add icon" />
              <label htmlFor="upload-photo02" className="add-file-input">
                Añadir archivo nº 2
              </label>
              <input
                className="d-block w-25 upload-photo"
                type="file"
                name="attachment02"
                id="upload-photo02"
                // multiple
                onChange={e => {
                  e.preventDefault();
                  let file = e.target.files[0];

                  if (!file) {
                    setDisplayFrmGrp03(false);
                    return;
                  }

                  setDisplayFrmGrp03(true);
                  setFieldValue("attachment02", file);
                  setFileName02(file.name);
                }}
              />
              {fileName02 && <small className="d-block">{fileName02} - <a href="javascript:;"  onClick={e => {
                setFieldValue("attachment02", null);
                   setFileName02(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>
              
              }
              <small className="d-block">
                (Extensiones permitidas: .doc, .docx y .pdf).
              </small>
            </FormGroup>

            <FormGroup className={`frmGrp03 ${isVisibleFrmGrp03 ? "" : "hidden"}`}>
              <img src={AddFile} className="pr-1" alt="add icon" />
              <label htmlFor="upload-photo03" className="add-file-input">
                Añadir archivo nº 3
              </label>
              <input
                className="d-block w-25 upload-photo"
                type="file"
                name="attachment03"
                id="upload-photo03"
                // multiple
                onChange={e => {
                  e.preventDefault();
                  let file = e.target.files[0];

                  if (!file) {
                    setDisplayFrmGrp04(false);
                    return;
                  }

                  setDisplayFrmGrp04(true);
                  setFieldValue("attachment03", file);
                  setFileName03(file.name);
                }}
              />
              {fileName03 && <small className="d-block">{fileName03} - <a href="javascript:;"   onClick={e => {
                    setFieldValue("attachment03", null);
                   setFileName03(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}
              <small className="d-block">
                (Extensiones permitidas: .doc, .docx y .pdf).
              </small>
            </FormGroup>

            <FormGroup className={`frmGrp04 ${isVisibleFrmGrp04 ? "" : "hidden"}`}>
              <img src={AddFile} className="pr-1" alt="add icon" />
              <label htmlFor="upload-photo04" className="add-file-input">
                Añadir archivo nº 4
              </label>
              <input
                className="d-block w-25 upload-photo"
                type="file"
                name="attachment04"
                id="upload-photo04"
                // multiple
                onChange={e => {
                  e.preventDefault();
                  let file = e.target.files[0];

                  if (!file) {
                    return;
                  }

                  setFieldValue("attachment04", file);
                  setFileName04(file.name);
                }}
              />
              {fileName04 && <small className="d-block">{fileName04} - <a href="javascript:;"  onClick={e => {
                   setFileName04(null);
                }}>
                <img src={Trash} className="pr-1" alt="add icon" /> [Eliminar]
              </a ></small>}
              <small className="d-block">
                (Extensiones permitidas: .doc, .docx y .pdf).
              </small>
            </FormGroup>

            <SubmitBtn
              color="orange"
              className="mt-4"
              text="Enviar"
              loading={isSubmitting}
            />
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  formTypes: state.formTypes.formTypes,
  loading: state.formTypes.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getFormTypes,
    addContactForm
  })(ContactForm)
);
