import React from "react";
import { Row, Col } from "reactstrap";
import PageWrapper from "../PageWrapper";

import IconWeb from "../../assets/img/icon-web.svg";
import Mail from "../../assets/img/icon-mail.svg";
import Phone from "../../assets/img/phone-icon.svg";
import Facebook from "../../assets/img/icon-face.svg";
import Twitter from "../../assets/img/icon-twitter.svg";
import Instagram from "../../assets/img/icon-instagram.svg";

const Content = () => {
  return (
    <PageWrapper fluid={true}>
      <Col xs={12} md={10} lg={8}>
        <Row className="mx-0">
          <Col xs={12}>
            <h2 className="resultado-busqueda__title text-initial">
              Conocé la red de centros Vacunar
            </h2>
            <p>
              Las vacunas son muy importantes para el cuidado de la salud de
              toda la comunidad.
            </p>
            <p>Siempre cerca tuyo informandote para cuidarte.</p>
          </Col>
          <Col xs={12} md={6}>
            <ul className="mt-4 att-cliente__items">
              <li className="att-socio__li">
                <a
                  href="https://www.vacunar.com.ar/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    src={IconWeb}
                    className="att-socio-icons"
                    alt="web icon"
                  />{" "}
                  www.vacunar.com.ar
                </a>
              </li>
              <li className="att-socio__li">
                <a href="mailto:info@vacunar.com.ar">
                  <img src={Mail} className="att-socio-icons" alt="mail icon" />{" "}
                  info@vacunar.com.ar
                </a>
              </li>
              <li className="att-socio__li">
                <a href="tel:+01108104448228">
                  <img
                    src={Phone}
                    className="att-socio-icons"
                    alt="phone icon"
                  />{" "}
                  0810-444-VACUNAR(8228)
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6}>
            <ul className="mt-4 att-cliente__items">
              <li className="att-socio__li">
                <a
                  href="https://www.facebook.com/CentrosVacunar/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    src={Facebook}
                    className="att-socio-icons"
                    alt="facebook icon"
                  />{" "}
                  CentrosVacunar
                </a>
              </li>
              <li className="att-socio__li">
                <a
                  href="https://twitter.com/vacunar"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    src={Twitter}
                    className="att-socio-icons"
                    alt="twitter icon"
                  />{" "}
                  @Vacunar
                </a>
              </li>
              <li className="att-socio__li">
                <a
                  href="https://www.instagram.com/vacunar/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <img
                    src={Instagram}
                    className="att-socio-icons"
                    alt="instagram icon"
                  />{" "}
                  @Vacunar
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12}>
            <ul className="att-cliente__items">
              <li className="att-socio__li mt-3">
                <a
                  href="https://vacunar.com.ar/mapa-sedes/"
                  className="location-map-link"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Ver centros de vacunación
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </PageWrapper>
  );
};

export default Content;
