import React, { useEffect, useCallback, useState } from "react";
import { Table, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getFacturas, resetFacturas } from "../../redux/actions/facturas";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/facturas.svg";
import TableLoading from "../TableLoading";
import FacturasItem from "./FacturasItem";
import impa from "./FacturasItem";
import axios from "axios";
import Moment from "react-moment";

const Facturas = ({
  user,
  facturas,
  loading,
  history,
  getFacturas,
  resetFacturas,
}) => {
  const getData = useCallback(async () => {
    const response = await getFacturas();
    if (response.status !== 200) {
      history.push("/panel");
    }
  }, [getFacturas, history]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => resetFacturas();
  }, [resetFacturas]);


  const [prefacturas, setPrefacturas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dni = user.personal_id;
      try {
        const response = await axios.post('https://api.altasalud.com.ar/api/web/prefactura', {
          dni: dni
        }); 

        setPrefacturas(response.data.prefactura);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <>
      <PanelTitle icon={Icon}>Facturas</PanelTitle>
      {loading ? (
        <div className="mt-5">
          <TableLoading />
        </div>
      ) : (
        <>
          <Row noGutters>
            <Col>
              {facturas.length === 0 ? (
                <h2 className="my-5">No se encontro nada.</h2>
              ) : (
                <>
                  <Table responsive className="tables-dashboard mt-3 mt-sm-5">
                    <thead>
                      <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Total</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {facturas.map((factura) => (
                        <FacturasItem key={factura.factura} {...factura} />
                      ))}
                      
                    </tbody>
                  </Table>
                </>
              )}

              <Row noGutters>
                <Col>
                  <button
                    onClick={() => {
                    //history.push("/preguntas-frecuentes?ff=true");
                      const win = window.open("/preguntas-frecuentes?ff=true", "_blank");
                      win.focus();
                    }}
                    className="link-descarga"
                  >
                    Ver medios de pago
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3 style={{marginTop:'100px',marginBottom:'20px'}}>Pre Factura</h3>
          {prefacturas.length === 0 ? (
            <h4>No hay datos cargados.</h4>) :(
          <>
          <Table responsive className="tables-dashboard mt-3 mt-sm-3">
                    <thead>
                      <tr>
                        <th scope="col">Fecha</th>
                        <th scope="col">Total</th>
                        <th scope="col">Aclaración</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                    {prefacturas.map((prefactura) => (
                      <>
                        <td key={prefactura.pre_factura}>
                        <Moment locale="es" format="LL">
                        {prefactura.fecha_emision}
                        </Moment>
                        
                        </td>
                        <td>
                        $ {prefactura.pre_factura}
                          
                        </td>
                      </>
                    ))}
                      <td>Más impuestos en caso de corresponder</td>
                    </tr>
                    </tbody>
          </Table>
          </>)}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  facturas: state.facturas.facturas,
  loading: state.facturas.loading,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getFacturas,
  resetFacturas,
})(Facturas);
