import React, { useEffect } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { FormGroup, FormFeedback } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { SignIn, getDocTypes } from "../redux/actions/auth";

import SubmitBtn from "./SubmitBtn";
import { customStyles } from "./styles/common";

const SignInForm = ({
  SignIn,
  getDocTypes,
  loading,
  docTypes,
  history,
  auth
}) => {
  useEffect(() => {
    const fetchTypeDocs = async () => await getDocTypes();

    fetchTypeDocs();
  }, [getDocTypes]);

  useEffect(() => {
    if (auth) {
      history.push("/panel");
    }
  }, [auth, history]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ numdoc: "", tipdoc: "", password: "" }}
      validationSchema={Yup.object().shape({
        numdoc: Yup.string().required("Requerido."),
        password: Yup.number().required("Requerido."),
        tipdoc: Yup.string().required("Requerido")
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const parsedData = {
          numdoc: values.numdoc,
          tipdoc: values.tipdoc.value,
          password: values.password
        };

        const response = await SignIn(parsedData);

        if (response !== 200) {
          setSubmitting(false);
          return;
        }

        history.push("/panel");
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        handleBlur,
        values,
        setFieldValue
      }) => (
        <Form className="mt-4">
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <FormGroup>
              <Select
                className={`${
                  errors.tipdoc && touched.tipdoc ? "select-error" : ""
                }`}
                classNamePrefix="custom-select"
                placeholder="Tipo de documento"
                styles={customStyles}
                options={docTypes}
                value={values.tipdoc}
                onChange={value => {
                  setFieldValue("tipdoc", value);
                }}
                isLoading={loading}
                inputId="tipodoc"
                instanceId="tipodoc"
              />
              {errors.tipdoc && touched.tipdoc && (
                <FormFeedback className="d-block">{errors.tipdoc}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Field
                type="text"
                name="numdoc"
                className={`form-control form-control__round ${errors.numdoc &&
                  touched.numdoc &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                value={values.numdoc}
                autoComplete="username"
                placeholder="Ingrese su número de documento"
              />
              {errors.numdoc && touched.numdoc ? (
                <FormFeedback className="d-block">{errors.numdoc}</FormFeedback>
              ) : (
                <small className="form-text text-muted text-danger">
                 
                </small>
              )}
            </FormGroup>

            <FormGroup>
              <Field
                type="password"
                name="password"
                className={`form-control form-control__round ${errors.password &&
                  touched.password &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                value={values.password}
                autoComplete="password"
                placeholder="Ingrese su contraseña"
              />
              {errors.password && touched.password && (
                <FormFeedback className="d-block">
                  {errors.password}
                </FormFeedback>
              )}
            </FormGroup>

            <SubmitBtn
              color="orange"
              className="mt-4"
              text="Entrar"
              loading={isSubmitting}
            />
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.types.loading,
  docTypes: state.auth.types.data,
  auth: state.auth.isAuthenticated
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      SignIn,
      getDocTypes
    }
  )(SignInForm)
);
