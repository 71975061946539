import React from "react";
import ContentLoader from "react-content-loader";

const FormLoading = ({ textarea }) => {
  return (
    <>
      <ContentLoader
        height={250}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        className="loaderContent"
        preserveAspectRatio="none"
      >
        {!textarea ? (
          <>
            <rect x="0" y="10" rx="5" ry="5" width="100%" height="15" />

            <rect x="0" y="50" rx="5" ry="5" width="100%" height="15" />

            <rect x="0" y="90" rx="5" ry="5" width="100%" height="15" />

            <rect x="0" y="130" rx="5" ry="5" width="100%" height="15" />

            <rect x="0" y="170" rx="5" ry="5" width="100%" height="15" />
          </>
        ) : (
          <>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
          </>
        )}

        <rect x="0" y="210" rx="5" ry="5" width="20%" height="15" />
      </ContentLoader>
    </>
  );
};

export default FormLoading;
