import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Credencial from "../../assets/img/credencial.svg";
import Actualizacion from "../../assets/img/actualizacion.svg";
import Info from "../../assets/img/info.svg";
import Sugerencias from "../../assets/img/sugerencias.svg";
import Icon from "../../assets/img/tramites.svg";

const TramitesPanel = ({ match }) => (
  <>
    <PanelTitle icon={Icon}>Tramites</PanelTitle>
    <Row className="mx-0 mt-5">
      <Col xs={12} md={6} className="pb-4">
        <Link
          to={`${match.path}/pedido-credencial`}
          className="tramites__container"
        >
          <img
            src={Credencial}
            alt="credencial icon"
            className="mt-5 tramites__titles__img"
          />
          <hr className="section-banner_info__line" />
          <h3 className="tramites__titles-boxes">
            Pedido
            <br />
            de credencial
          </h3>
        </Link>
      </Col>
      <Col xs={12} md={6} className="pb-4">
        <Link
          to={`${match.path}/actualizacion-datos-personales`}
          className="tramites__container"
        >
          <img
            src={Actualizacion}
            alt="actualizacion icon"
            className="mt-5 tramites__titles__img"
          />
          <hr className="section-banner_info__line" />
          <h3 className="tramites__titles-boxes">
            Actualización de
            <br />
            datos personales
          </h3>
        </Link>
      </Col>
      <Col xs={12} md={6} className="pb-4">
        <Link
          to={`${match.path}/solicitud-informacion`}
          className="tramites__container"
        >
          <img
            src={Info}
            alt="info icon"
            className="mt-5 tramites__titles__img"
          />
          <hr className="section-banner_info__line" />
          <h3 className="tramites__titles-boxes">
            Solicitud de
            <br />
            información
          </h3>
        </Link>
      </Col>
      <Col xs={12} md={6} className="pb-4">
        <Link
          to={`${match.path}/sugerencias-mejoras`}
          className="tramites__container"
        >
          <img
            src={Sugerencias}
            alt="info icon"
            className="mt-5 tramites__titles__img"
          />
          <hr className="section-banner_info__line" />
          <h3 className="tramites__titles-boxes">
            Sugerencias y
            <br />
            Mejoras
          </h3>
        </Link>
      </Col>
    </Row>
  </>
);

export default TramitesPanel;
