import React from "react";

const PageTitle = ({ children, thin }) => (
  <h4
    className={`${
      thin ? "semi-bold-font orange__titles" : "regamento__titles"
    } my-3`}
  >
    {children}
  </h4>
);

export default PageTitle;
