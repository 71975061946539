import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import { API_URL, setTokenHeaders } from "../api";
import {
  GET_TYPEDOCS_SUCCESS,
  GET_TYPEDOCS_FAILURE,
  GET_DOCTYPES_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS
} from "./types/auth";

export const getDocTypes = () => async dispatch => {
  dispatch({ type: GET_DOCTYPES_LOADING });

  try {
    const response = await axios.get(`${API_URL}/api/web/member/tipdoc`);

    dispatch({
      type: GET_TYPEDOCS_SUCCESS,
      payload: {
        types: _.map(response.data, (value, key) => ({
          value: key,
          label: value
        }))
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_TYPEDOCS_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      //LOGOUT
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado al iniciar sesión. 0002");
    }
    return error;
  }
};

export const SignIn = data => async dispatch => {
  try {
    const response = await axios.post(`${API_URL}/api/web/member/login`, data);

    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: {
        token: response.data.access_token,
        user: {
          ...response.data.member
        }
      }
    });

    const { access_token } = response.data;

    sessionStorage.setItem("jwtToken", access_token);
    setTokenHeaders(access_token);

    toast.success(`Bienvenido ${response.data.member.full_name}`);

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message+" La petición estaba bien formada pero no se pudo seguir debido a errores de semántica.");
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message+ " El cliente no posee los permisos necesarios para cierto contenido");
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (No hay conexion con el API 0001)");
    }
    return error;
  }
};

export const LogOut = () => async dispatch => {
  sessionStorage.removeItem("jwtToken");
  setTokenHeaders(false);
  dispatch({ type: SIGN_OUT_SUCCESS });

  toast.success(`Haz cerrado sesión satisfactoriamente.`);
};
